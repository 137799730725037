import React from 'react';
import Card, { CardHeader } from 'material-ui/Card';
import IconTint from "../IconTint"
import { Rights, hasRight } from '../Roles'
import {authUser} from '../auth/firestoreAuthClient'

const tintColor = "#00a651"

const styles = {
    card: { borderLeft: 'solid 4px ' + tintColor, flex: '1', marginRight: '1em', textDecoration: "none" },
    icon: { width: '64', height: '64', color: tintColor },
    div: { padding: 16, float: 'right' }
};

import Icon from "../icons/storageIcon.png"

export default ({ value }) => (
    <Card style={styles.card} {... (authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? {component: "a", href: "#/storagelocations/" } : {}) } >
        <div style={styles.div}><IconTint {...styles.icon} src={Icon} /></div>
        <CardHeader title={value} subheader="Total Storage Locations" />
    </Card>
);
