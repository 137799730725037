import firebase from 'firebase'
import arraySort from 'array-sort'
import firestore from 'firebase/firestore'
import get from 'lodash.get'

import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY
} from 'react-admin'

/**
 * @param {string[]|Object[]} trackedResources Array of resource names or array of Objects containing name and
 * optional path properties (path defaults to name)
 * @param {Object} firebaseConfig Optiona Firebase configuration
 */

const timestampFieldNames = {
    createdAt: 'created_at',
    updatedAt: 'updated_at'
}

export default (trackedResources = [], firebaseConfig = {}, options = {}) => {

    Object.assign(timestampFieldNames, options.timestampFieldNames)

    /** TODO Move this to the Redux Store */
    const resourcesStatus = {}
    const resourcesReferences = {}
    const resourcesData = {}
    const resourcesPaths = {}

    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig)
    }

    const db = firebase.firestore()

    trackedResources.map(resource => {
        if (typeof resource === 'object') {
            if (!resource.name) {
                throw new Error(`name is missing from resource ${resource}`)
            }

            const path = resource.path || resource.name
            const name = resource.name

            // Check path ends with name so the initial children can be loaded from on 'value' below.
            const pattern = path.indexOf('/') >= 0 ? `/${name}$` : `${name}$`
            if (!path.match(pattern)) {
                throw new Error(`path ${path} must match ${pattern}`)
            }

            resourcesPaths[name] = path
            resource = name
        } else {
            resourcesPaths[resource] = resource
        }

        resourcesData[resource] = {}
        resourcesStatus[resource] = new Promise(resolve => {
            let ref = resourcesReferences[resource] = db.collection(resourcesPaths[resource])
            ref.onSnapshot(function(snapshot) {
                snapshot.docChanges.forEach(function(change) {
                    if (change.type === "added") {
                        resourcesData[resource][change.doc.id] = change.doc.data()
                        resourcesData[resource][change.doc.id].id = change.doc.id
                    }
                    if (change.type === "modified") {
                        resourcesData[resource][change.doc.id] = change.doc.data()
                    }
                    if (change.type === "removed") {
                        if (resourcesData[resource][change.doc.id]) { delete resourcesData[resource][change.doc.id] }

                    }
                })
                resolve()
            })
        })
    })

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */

    return (type, rawResource, params) => {

        console.log("type=", type, "resource=", rawResource, "params=", params)

        var resource = rawResource
        switch (rawResource) {
            case 'sentmessages':
                resource = 'messages'
                break
            case 'receivedmessages':
                resource = 'messages'
                break
        }

        return new Promise((resolve, reject) => {
            resourcesStatus[resource].then(() => {
                Object.byString = function(o, s) {
                    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
                    s = s.replace(/^\./, '');           // strip a leading dot
                    var a = s.split('.');
                    for (var i = 0, n = a.length; i < n; ++i) {
                        var k = a[i];
                        if (k in o) {
                            o = o[k];
                        } else {
                            return;
                        }
                    }
                    return o;
                }
                var deepSearch = function (x, y) {  // x is needle, y is haystack
                    if (x === y) {
                        return true;
                    }
                    else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
                        if (Object.keys(x).length > Object.keys(y).length)
                            return false;

                        for (var prop in x) {
                            if (y.hasOwnProperty(prop))
                            {
                                if (! deepSearch(x[prop], y[prop]))
                                    return false;
                            }
                            else
                                return false;
                        }

                        return true;
                    }
                    else
                        return false;
                }
                switch (type) {
                    case GET_LIST:
                    case GET_MANY:
                    case GET_MANY_REFERENCE:

                        let ids = []
                        let data = []
                        let total = 0

                        if (params.ids) {
                            /** GET_MANY */
                            params.ids.map(key => {
                                if (resourcesData[resource][key]) {
                                    ids.push(key)
                                    data.push(resourcesData[resource][key])
                                    total++
                                }
                            })
                        } else if (params.pagination) {
                            /** GET_LIST / GET_MANY_REFERENCE */
                            let values = []

                            // Copy the filter params so we can modify for GET_MANY_REFERENCE support.
                            const filter = Object.assign({}, params.filter)

                            if (params.target && params.id) {
                                filter[params.target] = params.id
                            }

                            const filterKeys = Object.keys(filter)
                            /* TODO Must have a better way */
                            if (filterKeys.length) {
                                Object.values(resourcesData[resource]).map(value => {

                                    let filterIndex = 0
                                    while (filterIndex < filterKeys.length) {
                                        let property = filterKeys[filterIndex]
                                        if (property !== 'q' && !deepSearch(filter[property], get(value, property))) {
                                            return
                                        } else if (property === 'q') {
                                            if (JSON.stringify(value).indexOf(filter['q']) === -1) {
                                                return
                                            }
                                        }
                                        filterIndex++
                                    }
                                    values.push(value)
                                })
                            } else {
                                values = Object.values(resourcesData[resource])
                            }

                            if(params.sort) {
                                arraySort(values, params.sort.field, {reverse: params.sort.order !== 'ASC'})
                            }

                            const {page, perPage} = params.pagination
                            if (perPage > 0) {
                                const _start = (page - 1) * perPage
                                const _end = page * perPage
                                data = values.slice(_start, _end)
                                ids = Object.keys(resourcesData[resource]).slice(_start, _end)
                            }
                            else {
                                data = values
                                ids = Object.keys(resourcesData[resource])
                            }
                            total = values.length
                        } else {
                            console.error('Unexpected parameters: ', params, type)
                            reject(new Error('Error processing request'))
                        }
                        resolve({ data, ids, total })
                        return

                    case GET_ONE:
                        const key = params.id
                        if (key && resourcesData[resource][key]) {
                            resolve({
                                data: resourcesData[resource][key]
                            })
                        } else {
                            reject(new Error('Key not found'))
                        }
                        return

                    case DELETE:
                        db.collection(resourcesPaths[resource]).doc(params.id).delete()
                            .then(() => { resolve({ data: params.id }) })
                            .catch(reject)
                        return


                    case DELETE_MANY:
                        let writeBatch = db.batch()
                        params.ids.forEach(id => writeBatch.delete(db.collection(resourcesPaths[resource]).doc(id)))
                        writeBatch.commit().then(() => {
                            resolve({ data: params.ids })
                        }).catch(reject)
                        return

                    case UPDATE:
                        const dataUpdate = Object.assign({ [timestampFieldNames.updatedAt]: Date.now() }, resourcesData[resource][params.id], params.data)

                        db.collection(resourcesPaths[resource]).doc(params.id).update(dataUpdate)
                            .then(() => resolve({ data: dataUpdate }))
                            .catch(reject)
                        return

                    case CREATE:
                        let docRef = db.collection(resourcesPaths[resource]).doc()
                        let newItemKey = params.data.id
                        if (!newItemKey) {
                            newItemKey = docRef.id
                        } else if (resourcesData[resource] && resourcesData[resource][newItemKey]) {
                            reject(new Error('ID already in use'))
                            return
                        }
                        const dataCreate = Object.assign(
                            {
                                [timestampFieldNames.createdAt]: Date.now(),
                                [timestampFieldNames.updatedAt]: Date.now()
                            },
                            params.data,
                            {
                                id: newItemKey,
                                key: newItemKey
                            }
                        )
                        docRef.set(dataCreate)
                            .then(() => resolve({ data: dataCreate }))
                            .catch(reject)
                        return

                    default:
                        console.error('Undocumented method: ', type)
                        return {data: []}
                }
            })
        })
    }
}
