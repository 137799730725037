import React from 'react';
import {
    ImageInput,
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    ImageField,
    Show,
    ShowButton,
    SimpleShowLayout,
    SimpleForm,
    TextInput
} from 'react-admin';
import { ScalableImageField } from '../fields/ScalableImageField'
import SingleImageField from '../fields/SingleImageField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'

const CategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

import Icon from '../icons/categoryIcon.png'
import IconTint from '../IconTint'
export const CategoryIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const CategoryList = (props) => (
    <DocumentTitle title="Binsto - Initial Categories">
        <List title="Initial Categories" {...props} filters={<CategoryFilter />}>
            <Datagrid>
                <TextField source="name" />
                <ScalableImageField source="icon" sortable={false} elStyle={{height: "48px", padding: "4px"}}/>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const CategoryTitle = ({ record }) => {
    return <span>Base Category {record ? `"${record.name}"` : ''}</span>;
};

export const CategoryShow = (props) => (
    <DocumentTitle title="Binsto - Show Initial Category">
        <Show title={<CategoryTitle />} {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="description" />
                <ImageField label="Category Icon" source="icon" placeholder={<p>Drop your image file here or click to select one.</p>}>
                    <SingleImageField />
                </ImageField>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const CategoryEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Initial Category">
        <Edit title={<CategoryTitle />} {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" />
                <ImageInput label="Category Icon" source="icon" placeholder={<p>Drop your image file here or click to select one.</p>}>
                    <SingleImageField />
                </ImageInput>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const CategoryCreate = (props) => (
    <DocumentTitle title="Binsto - Create Initial Category">
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" />
                <ImageInput label="Category Icon" source="icon" placeholder={<p>Drop your image file here or click to select one.</p>}>
                    <SingleImageField />
                </ImageInput>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);