export const validateNameAndOwner = (values, allValues) => {
    const errors = {};
    if (!values._info || !values._info.ownedBy) {
        _.set(errors, '_info.ownedBy', ['You must specify an owner']);
    }
    if (!values.name) {
        errors.name = ['You must specify a name'];
    }
    return errors
};

