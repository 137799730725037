import React, {Component} from 'react';
import get from 'lodash.get'
import {Map, GoogleApiWrapper} from 'google-maps-react';

export class GoogleMapsField extends Component {
    render() {
        const location = get(this.props.record, this.props.source)
        return (typeof location !== 'undefined') ? (
            <Map google={this.props.google} className='googleMap' initialCenter={{
                lat: location.latitude,
                lng: location.longitude
            }} options={{version: "3.30"}} zoom={14} style={{width: '400px', height: '200px', position: 'relative'}}>
            </Map>
        ) : <span/>;
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDHAkJCTTK8P1TaThMNJM6DoiFWZAhPDtc"
})(GoogleMapsField)
