import React from 'react';

const styles = {
    img: {
        height: 64,
        width: 64,
        borderRadius: 4,
        padding: 2
    },
    main: {
        display: 'flex',
        flexWrap: 'wrap'
    }
};

const FirstImageField = ({ record, source, count=1  }) =>
    <span style={styles.main}>
        {(record[source] || []).slice(0, count).map(imageURL => (
            <img  key={imageURL} style={styles.img} src={imageURL} alt="" />
        ))}
    </span>;

FirstImageField.defaultProps = {
    addLabel: true
};

export default FirstImageField;
