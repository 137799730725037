import React from 'react';
import PropTypes from 'prop-types';
import inflection from 'inflection';
import TextField from 'material-ui/TextField';
import * as ReactColor from 'react-color';
import { addField, FieldTitle } from 'ra-core';
import sanitizeRestProps from './sanitizeRestProps';

require('./ColorInput.css');

class ColorInput extends React.Component {
    state = {
        show: false
    };

    handleOpen = () => this.setState({ show: true });
    handleClose = () => this.setState({ show: false });
    handleChange = ({ hex }) => {
        this.props.input.onChange(hex);
        this.forceUpdate();
    };

    render() {
        const {
            addLabel,
            label,
            source,
            touched,
            error,
            elStyle,
            options,
            picker,
            input,
            className,
            ...rest
        } = this.props;

        const Picker = ReactColor[`${picker}Picker`];

        return (
            <div {...sanitizeRestProps(rest)}>
                <TextField
                    {...input}

                    className={className}
                    onFocus={this.handleOpen}
                    floatinglabeltext={ label || inflection.humanize(source) }
                    errortext={touched && error}
                    style={elStyle}
                    label={
                        <div onClick={this.handleOpen}><FieldTitle
                            label={label}
                            source={source}
                        /><span>&nbsp;&nbsp;</span><span style={{backgroundColor: input.value}}>&nbsp;&nbsp;&nbsp;</span></div>
                    }
                />
                {
                    this.state.show?
                        <div className="ColorInput-popup">
                            <div
                                className="ColorInput-cover"
                                onClick={this.handleClose}
                            />
                            <Picker
                                {...options}
                                color={input.value}
                                onChange={this.handleChange}
                            />
                        </div>
                        : null
                }
            </div>
        )
    }
};

ColorInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.object,
    source: PropTypes.string,
    input: PropTypes.object,
    picker: (props, propName, componentName) =>
        !ReactColor[`${props[propName]}Picker`] &&
        new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`)
};

ColorInput.defaultProps = {
    addLabel: true,
    label: "Color",
    picker: 'Chrome',
    options: {
        disableAlpha: true
    }
};

export const ColorInputWithField = addField(ColorInput);
export default ColorInputWithField
