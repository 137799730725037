import React from 'react';
import Card,  { CardHeader } from 'material-ui/Card';
import IconTint from "../IconTint"
import { statusChoices } from '../viewers/Orders'
import List, { ListItem } from 'material-ui/List';

const tintColor = "#7b0046"

const styles = {
    card: { borderLeft: 'solid 4px ' + tintColor, flex: '1', marginRight: '1em', minHeight: '96px', textDecoration: "none" },
    icon: { width: '64', height: '64', color: tintColor },
    div: { padding: 16, float: 'right' },
    leftList: { width: "50%", fontFamily: "Roboto" },
    rightList: { width: "50%", textAlign: "right", fontFamily: "Roboto" },
    lastLeftList: { width: "50%", fontFamily: "", fontFamily: "Roboto", fontWeight: 'bold' },
    lastRightList: { width: "50%", textAlign: "right", fontFamily: "Roboto", fontWeight: 'bold' }
};

import Icon from "../icons/orderIcon.png"

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
});

export default ({ values = [] }) => (
    <Card style={styles.card} component="a" href={`#/orders/`}>
        <div style={styles.div}><IconTint {...styles.icon} src={Icon} /></div>
        <CardHeader title={values.length} subheader="Orders" />
        <List>
            {statusChoices.map(record =>
                <ListItem key={record.id}>
                        <div style={styles.leftList}>{record.name}</div>
                        <div style={styles.rightList}>{formatter.format(values.filter(value => value.status == record.id).reduce((accumulator, currentValue) => accumulator + currentValue.totalAmount, 0))}</div>
                </ListItem>
            )}
            <ListItem>
                <div style={styles.lastLeftList}>Total</div>
                <div style={styles.lastRightList}>{formatter.format(values.reduce((accumulator, currentValue) => accumulator + currentValue.totalAmount, 0))}</div>
            </ListItem>
        </List>
    </Card>
);
