/* globals localStorage */
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin'
import firebase from 'firebase'
import firestore from 'firebase/firestore'

export var authUser = null

Promise.retry = function(fn, times, delay) {
    return new Promise(function(resolve, reject){
        var error;
        var attempt = function() {
            if (times == 0) {
                reject(error);
            } else {
                fn().then(resolve)
                    .catch(function(e){
                        times--;
                        error = e;
                        setTimeout(function(){attempt()}, delay);
                    });
            }
        };
        attempt();
    });
};

function readProfileAndBrand(auth, resolve, reject) {
    const db = firebase.firestore()
    db.collection('profiles').doc(auth.uid).get()
        .then(function (snapshot) {
            const profile = snapshot.data()
            // TODO make it a parameter
            if (snapshot.exists && profile && !profile.isDisabled) {
                auth.getIdToken().then((firebaseToken) => {
                    let user = {auth, profile, firebaseToken}
                    localStorage.setItem('firebaseToken', firebaseToken)
                    localStorage.setItem('role', profile.role || "user")
                    authUser = user
                    db.collection('brands').doc(profile.brand).get()
                        .then(function (snapshot) {
                            user.brand = snapshot.data()
                            resolve(user)
                        })
                    // TODO improve this! Save it on redux or something
                }).catch(err => {
                    reject(err)
                })
            } else {
                firebase.auth().signOut()
                reject(new Error('Access Denied - Account Suspended'))
            }
        })
        .catch(err => {
            reject(err)
        })
}

function firebaseAuthCheck (auth, resolve, reject) {
    authUser = null
    if (auth) {
        readProfileAndBrand(auth, resolve, function(arg) {
            setTimeout(readProfileAndBrand(auth, resolve, reject), 3000)
        })
    } else {
        reject(new Error('Login failed!'))
    }
}

export default (type, params) => {

    if (type === AUTH_LOGOUT) {
        if (authUser) {
            authUser = null
            return firebase.auth().signOut()
        }
        else {
            return null
        }
    }
    if (type === AUTH_CHECK) {
        return new Promise((resolve, reject) => {
            if (authUser) {
                resolve()
            } else {
                reject(new Error('User not found'))
            }
        })
    }
    /*
    if (type === AUTH_ERROR) {
        // called when the API returns an error
        const { status } = params;
        if (status === 401 || status === 403) {
            authUser = null
            return Promise.reject();
        }
        return Promise.resolve();
    }
    */
    if (type === AUTH_LOGIN) {
        authUser = params
        return new Promise((resolve, reject) => {
            firebaseAuthCheck(params, resolve, reject)
        })
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role');
        return Promise.resolve(role);
    }

    return Promise.reject('Unknown method');
}
