import React from 'react';
import {
    DateField,
    BooleanField,
    BooleanInput,
    Filter,
    List,
    Edit,
    Create,
    DateInput,
    Datagrid,
    ReferenceField,
    TextField,
    EditButton,
    LongTextInput,
    Show,
    ShowButton,
    ReferenceInput,
    SelectInput,
    SimpleShowLayout,
    SimpleForm,
    TextInput
} from 'react-admin';
import Icon from 'material-ui-icons/Message';
import FullNameField from '../fields/FullNameField'
import MessageAboutField from '../fields/MessageAboutField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'
import FilterUserInput from '../inputs/FilterUserInput'
import { Rights, hasRight } from '../Roles'

const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <FilterUserInput source="fromUserID" label="From User"/>
        <FilterUserInput source="toUserID" label="To User"/>
        <BooleanInput source="opened" label="Opened"/>
    </Filter>
);

export const MessageIcon = Icon

export const MessageList = ({ permissions, ...props }) => (
    <DocumentTitle title="Binsto - All Messages">
        <List
            title="All Messages"
            {...props}
            filters={<MessageFilter/>}

        >
            <Datagrid>
                {hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceField linkType="show" label="From" source="fromUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField> : null }
                <ReferenceField label="To" source="toUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <DateField label="Sent On" source="createdAt" />
                <TextField source="message"/>
                <MessageAboutField label="About"/>
                <BooleanField source="opened"/>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const MessageTitle = ({ record }) => {
    return <span>Message sent {record ? `"${record.createdAt}"` : ''}</span>;
};

export const MessageShow = (props) => (
    <DocumentTitle title="Binsto - Show Message">
        <Show title={<MessageTitle />} {...props}>
            <SimpleShowLayout>
                <ReferenceField linkType="show" label="From" source="fromUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <ReferenceField label="To" source="toUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <DateField label="Sent On" source="createdAt" />
                <TextField source="message"/>
                <MessageAboutField label="About"/>
                <BooleanField source="opened"/>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const MessageEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Message">
        <Edit title={<MessageTitle />} {...props}>
            <SimpleForm>
                <FilterUserInput source="fromUserID" label="From User"/>
                <FilterUserInput source="toUserID" label="To User"/>
                <DateInput label="Sent On" source="createdAt" />
                <LongTextInput source="message"/>
                <MessageAboutField label="About"/>
                <BooleanInput source="opened"/>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const MessageCreate = (props) => (
    <DocumentTitle title="Binsto - Create Message">
        <Create {...props}>
            <SimpleForm>
                <FilterUserInput source="fromUserID" label="From User"/>
                <FilterUserInput source="toUserID" label="To User"/>
                <DateInput label="Sent On" source="createdAt" />
                <LongTextInput source="message"/>
                <MessageAboutField label="About"/>
                <BooleanInput source="opened"/>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);