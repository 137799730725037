import React from 'react';
import {
    EditButton,
    ShowButton,
    Filter,
    List,
    Edit,
    Create,
    SimpleShowLayout,
    Datagrid,
    Show,
    SimpleForm
} from 'react-admin';
import ActionToolbar from '../uicomponents/ActionToolbar'
import DocumentTitle from 'react-document-title'

const TransactionsFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

import Icon from '../icons/transactionIcon.png'
import IconTint from '../IconTint'
export const TransactionsIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const TransactionsList = (props) => (
    <DocumentTitle title="Binsto - Transactions">
        <List title="Transactions" {...props} filters={<TransactionsFilter />}>
            <Datagrid>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const TransactionsTitle = ({ record }) => {
    return <span>Transaction {record ? `"${record.name}"` : ''}</span>;
};

export const TransactionsShow = (props) => (
    <DocumentTitle title="Binsto - Show Transaction">
        <Show title={<TransactionsTitle />} {...props}>
            <SimpleShowLayout>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const TransactionsEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Transaction">
        <Edit title={<TransactionsTitle />} {...props}>
            <SimpleForm>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const TransactionsCreate = (props) => (
    <DocumentTitle title="Binsto - Create Transaction">
        <Create {...props}>
            <SimpleForm>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);