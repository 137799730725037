import React from 'react';
import Card, { CardContent, CardActions } from 'material-ui/Card';
import HomeIcon from 'material-ui-icons/Home';
import CodeIcon from 'material-ui-icons/Code';
import Button from 'material-ui/Button';
import Typography from 'material-ui/Typography';

import { translate } from 'react-admin';

import { Rights, hasRight } from '../Roles'
import {authUser} from '../auth/firestoreAuthClient'

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

export default translate(({ translate }) => (
    <Card>
        <CardContent>
            <Typography variant="headline" component="h2">
                {
                    authUser && authUser.profile ? `Welcome ${authUser.profile.displayName} (${authUser.profile.email}) as ${authUser.profile.role ? authUser.profile.role.capitalize() : "Guest"} from ${authUser.brand ? authUser.brand.name : "BinSto"}` : ""
                }
            </Typography>
            <Typography component="p">
                Here you can manage your Binsto account based on your access privileges.
            </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button target="_blank" href={authUser ? authUser.brand.brandURL : ""}>
                <HomeIcon style={{ paddingRight: '0.5em' }} />
                {authUser ? authUser.brand.name : ""} website
            </Button>
            { (authUser && hasRight(authUser.profile.role, Rights.siteAdministration)) ?
                <Button target="_blank" href="https://itunes.apple.com/us/app/binsto/id1338853146?ls=1&mt=8">
                <CodeIcon style={{paddingRight: '0.5em'}}/>
                Binsto iOS app
                </Button> : null }
            { (authUser && !hasRight(authUser.profile.role, Rights.siteAdministration)) ?
                <Button target="_blank" href="https://www.binsto.com/contact">
                <CodeIcon style={{paddingRight: '0.5em'}}/>
                Contact Binsto
                </Button> : null }
            { (authUser && hasRight(authUser.profile.role, Rights.siteAdministration)) ?
                <Button target="_blank" href="https://www.branch.io">
                    <CodeIcon style={{paddingRight: '0.5em'}}/>
                    Manage Invitation URLs
                </Button> : null }

        </CardActions>
    </Card>
));


