import React from 'react';
import {
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    EditButton,
    SimpleShowLayout,
    ShowButton,
    SimpleForm
} from 'react-admin';
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'

import Icon from 'material-ui-icons/NoteAdd';

const UserCustomizationsFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

export const UserCustomizationsIcon = Icon

export const UserCustomizationsList = (props) => (
    <DocumentTitle title="Binsto - User Customizations">
        <List title="User Customizations" {...props} filters={<UserCustomizationsFilter />}>
            <Datagrid>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const UserCustomizationsTitle = ({ record }) => {
    return <span>Custom User Data for {record ? `"${record.name}"` : ''}</span>;
};

export const UserCustomizationsShow = (props) => (
    <DocumentTitle title="Binsto - Show User Customization">
        <Show title={<UserCustomizationsTitle />} {...props}>
            <SimpleShowLayout>
            </SimpleShowLayout>
        </Show>
        </DocumentTitle>
);

export const UserCustomizationsEdit = (props) => (
    <DocumentTitle title="Binsto - Edit User Customization">
        <Edit title={<UserCustomizationsTitle />} {...props}>
            <SimpleForm>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const UserCustomizationsCreate = (props) => (
    <DocumentTitle title="Binsto - Create User Customization">
        <Create title="Create User Customization" {...props}>
            <SimpleForm>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);