export const Rights = {
    all: "all",
    login: "login",
    userStorage: "userStorage",  // CRUD access to Items, Bins, StorageLocations for this user
    manageOrders: "manageOrders", // CRUD access to all products, orders, transactions, subscription plans for users belonging to this client
    userManagement: "userManagement",    // CRUD access to User accounts
    siteAdministration: "siteAdministration",
    uiBranding: "uiBranding",
    messages: "messages"
}

export const Roles = {
    admin: { rights: [ Rights.all ], name: "Administrator" },
    user: { rights: [ Rights.login, Rights.userStorage, Rights.messages], name: "User" },
    manager: { rights: [Rights.manageOrders, Rights.userStorage, Rights.userManagement, Rights.uiBranding, Rights.messages], name: "Manager" }
}

export const hasRight = (role, right) => {
    return Roles[role] && (Roles[role].rights.includes(right) || Roles[role].rights.includes(Rights.all))
}
