import React, { Component } from 'react';
import Card, { CardActions, CardContent } from 'material-ui/Card';
import {
    Button,
    showNotification as showNotificationAction,
    ViewTitle,
    CREATE,
    GET_LIST
} from 'react-admin';
import TextField from 'material-ui/TextField';
import MenuItem from 'material-ui/Menu/MenuItem'
import { push as pushAction } from 'react-router-redux';
import { connect } from 'react-redux'
import restClient from '../restClient'
import { Rights, hasRight } from '../Roles'
import {authUser} from '../auth/firestoreAuthClient'

class MessageBroadcast extends Component {

    constructor(props) {
        super(props);
        this.state = {textFieldValue: '', filterValue: 1}

        //this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleClick = () => {
        const { push, showNotification } = this.props;
        fetch(`/comments/YYY`, { method: 'PUT', body: "xxx" })  // TODO: GET RID OF THIS
            .then(() => {
                restClient(GET_LIST, 'profiles', hasRight(this.props.permissions, Rights.siteAdministration) ? { } : {
                        filter: { "brand": authUser.brand.id},
                        pagination: { page: 1, perPage: 0 },
                        sort: { field: '_info.createdAt', order: 'DESC' }
                    }).then(response => {
                    // send message to each matching user
                    response.data.forEach (
                        user => {
                            //console.log("SENDING MESSAGE TO: ", user.uid, " MESSAGE: " + this.state.textFieldValue)
                            restClient(CREATE, 'messages', { data: { aboutType: 0, createdAt: Date.now(), fromUserID: authUser.profile.uid,
                                toUserID: user.uid, opened: false, message: this.state.textFieldValue }})
                        }
                    )
                    this.setState({
                        textFieldValue: ""
                    });
                    showNotification(response.data.length + ' Messages Sent')
                } )

                // push('/dashboard');
            })
            .catch((e) => {
                console.error(e);
                showNotification('Error: message not sent', 'warning')
            });
    }

    getInitialState = () => {
        return {
            textFieldValue: '',
            filterValue: 1
        }
    }

    handleTextFieldChange = (event) => {
        this.setState({
            textFieldValue: event.target.value
        });
    }

    handleFilterFieldChange = (event) => {
        this.setState({
            filterValue: event.target.value
        });
    }

    render() {
        return (
            <Card>
                <ViewTitle title="Send Message to Users" />
                <CardContent>
                    <TextField
                        select
                        fullWidth
                        label="Group to Send To"
                        value={this.state.filterValue}
                        onChange={this.handleFilterFieldChange}
                    >
                        <MenuItem key={1} value={1}>All Users</MenuItem>
                        <MenuItem key={2} value={2}>New Users</MenuItem>
                        <MenuItem key={3} value={3}>Inactive Users</MenuItem>
                        <MenuItem key={4} value={4}>Open Shopping Cart Users</MenuItem>
                        <MenuItem key={5} value={5}>No Insurance Users</MenuItem>
                    </TextField>
                    <TextField
                        value={this.state.textFieldValue}
                        hinttext="Enter message here"
                        multiline={true}
                        rows={3}
                        fullWidth
                        label="Message Contents"
                        onChange={this.handleTextFieldChange}
                    />
                </CardContent>
                <CardActions>
                    <Button label="Send" onClick={this.handleClick}>Send</Button>
                </CardActions>
            </Card>
        )
    }

}

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(MessageBroadcast);

