import React from 'react';
import {
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
    EditButton,
    ReferenceInput,
    required,
    Show,
    SimpleShowLayout,
    ShowButton,
    SimpleForm
} from 'react-admin';
import FullNameField from '../fields/FullNameField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import DocumentTitle from 'react-document-title'
import FilterUserInput from '../inputs/FilterUserInput'
import { Rights, hasRight } from '../Roles'

const TagsFilter = ({permissions, ...props}) => (
    <Filter {...props}>
        <TextInput
            label="post.list.search"
            source="q"
            alwaysOn
        />
        { hasRight(permissions, Rights.siteAdministration) ?
            <FilterUserInput source="createdBy" label="Created By"/> : null }
    </Filter>
);

import Icon from '../icons/brandicon.png'
import IconTint from '../IconTint'
export const TagsIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const TagsList = ({ permissions, ...props}) => (
    <DocumentTitle title="Binsto - Tags">
        <List title="Tags" {...props} filters={<TagsFilter permissions={permissions}/>}>
            <Datagrid>
                <ReferenceField linkType="show" label="Created By" source="createdBy" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <TextField source="name" />
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const TagsTitle = ({ record }) => {
    return <span>Tag {record ? `"${record.name}"` : ''}</span>;
};

export const TagsShow = (props) => (
    <DocumentTitle title="Binsto - Show Tag ">
        <Show title={<TagsTitle />} {...props}>
            <SimpleShowLayout>
                <ReferenceField linkType="show" label="Created By" source="createdBy" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <TextField source="name" />
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

const validateTagFields = (values) => {
    const errors = {};
    if (!values.createdBy) {
        errors.createdBy = ['You must specify an owner'];
    }
    if (!values.name) {
        errors.name = ['You must specify a tag name'];
    }
    return errors
};

export const TagsEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Tag ">
        <Edit title={<TagsTitle />} {...props}>
            <SimpleForm validate={validateTagFields}>
                <FilterUserInput source="createdBy" label="Owner"/>
                <TextInput source="name"/>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const TagsCreate = (props) => (
    <DocumentTitle title="Binsto - Create Tag">
        <Create title="Create Tag" {...props}>
            <SimpleForm validate={validateTagFields}>
                <FilterUserInput source="createdBy" label="Owner"/>
                <TextInput source="name"/>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);