import React from 'react';
import {
    BooleanField,
    BooleanInput,
    NumberInput,
    SelectInput,
    TextField,
    TextInput,
    EditButton,
    ShowButton,
    NumberField,
    ImageInput,
    ImageField,
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    ReferenceField,
    ReferenceInput,
    Show,
    SimpleShowLayout,
    SelectField,
    SimpleForm
} from 'react-admin';
import FirstImageField from '../fields/FirstImageField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import DocumentTitle from 'react-document-title'
import { Rights, hasRight } from '../Roles'
import {authUser} from '../auth/firestoreAuthClient'

import Icon from '../icons/productIcon.png'
import IconTint from '../IconTint'

export const ProductsIcon = (props) => (<IconTint {...props} src={Icon}/>)

const styles = {
    hiddenField: {
        display: 'none'
    }
}

const priceRepeatChoices = [
    {id: "none", name: "None", label: ""},
    {id: "daily", name: "Per day", label: "Day"},
    {id: "weekly", name: "Per week", label: "Week"},
    {id: "monthly", name: "Per month", label: "Month"},
    {id: "annually", name: "Per year", label: "Year"}
]

const statusChoices = [
    {id: 0, name: "Enabled"},
    {id: 1, name: "Disabled"}
]

const ProductsFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
            label="post.list.search"
            source="q"
            alwaysOn
        />
        <BooleanInput label="Requires Shipping" source="requiresShipping"/>
        { hasRight(permissions, Rights.siteAdministration) ?
        <ReferenceInput label="CLIENT" source="brandId" reference="brands" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> : null }
    </Filter>
);

export const ProductsList = ({ permissions, ...props }) => (
    <DocumentTitle title="Binsto - Products">
        <List
            title="Products" {...props}
            filter={hasRight(permissions, Rights.siteAdministration) ? null : { "brandId": authUser ? authUser.brand.id : ""}}
            filters={<ProductsFilter
            permissions={permissions}/>}
        >
            <Datagrid>
                <TextField label="NAME" source="name"/>
                { hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceField label="CLIENT" source="brandId" reference="brands">
                    <TextField source="name"/>
                </ReferenceField>
                : null }
                <FirstImageField source="imageRefs" label="" sortable={false}/>
                <TextField label="DESCRIPTION" source="description"/>
                <NumberField label="PRICE" source="price" options={{ style: 'currency', currency: 'USD' }} />
                <SelectField label="REPEAT PERIOD" source="repeat" choices={priceRepeatChoices}/>
                <NumberField label="# AVAILABLE" source="count"/>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const ProductsTitle = ({ record }) => {
    return <span>Product {record ? `"${record.name}"` : ''}</span>;
};

export const ProductsShow = ({ permissions, ...props}) => (
    <DocumentTitle title="Binsto - Show Product">
        <Show title={<ProductsTitle />} {...props}>
            <SimpleShowLayout>
                <TextField label="NAME" source="name"/>
                { hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceField label="CLIENT" source="brandId" reference="brands">
                    <TextField source="name"/>
                </ReferenceField>
                : null }
                <TextField label="DESCRIPTION" source="description"/>
                <NumberField label="PRICE" source="price"/>
                <SelectField label="REPEAT PERIOD" source="repeat" choices={priceRepeatChoices}/>
                <NumberField label="DEPOSIT" source="deposit"/>
                <BooleanField label="REQUIRES SHIPPING" source="requiresShipping"/>
                <NumberField label="# AVAILABLE" source="count"/>
                <SelectField label="STATUS" source="status" choices={statusChoices}/>
                <FirstImageField source="imageRefs" label="PHOTOS" count="1000" sortable={false}/>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const validateFields = (values, allValues) => {
    const errors = {};
    if (!values.name) {
        errors.name = ['You must specify a product name'];
    }
    if (!values.description) {
        errors.description = ['You must specify a product description'];
    }
    if (!values.brandId) {
        errors.brandId = ['You must specify a client'];
    }
    if (!values.price) {
        errors.price = ['You must specify a price'];
    }
    return errors
};

export const ProductsEdit = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Edit Product">
        <Edit title={<ProductsTitle />} {...props}>
            <SimpleForm validate={validateFields}>
                <TextInput label="NAME" source="name"/>
                { hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceInput label="CLIENT" source="brandId" reference="brands" allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput> : <TextInput source="brandId" defaultValue={authUser ? authUser.brand.id : ""} style={styles.hiddenField}/> }
                <TextInput label="DESCRIPTION" source="description"/>
                <NumberInput label="PRICE" source="price"/>
                <SelectInput label="REPEAT PERIOD" source="repeat" choices={priceRepeatChoices}/>
                <NumberInput label="DEPOSIT" source="deposit"/>
                <BooleanInput label="REQUIRES SHIPPING" source="requiresShipping"/>
                <NumberInput label="# AVAILABLE" source="count" min={0} step={1}/>
                <SelectInput label="STATUS" source="status" choices={[ {id: 0, name: "Enabled"}, {id: 1, name: "Disabled"} ]}/>
                <ImageInput multiple format={v => v && v.map(i => {return {src: i, title: ""}})} parse={v => v.map(i => i.src)} label="PHOTOS" source="imageRefs" >
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const ProductsCreate = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Create Product">
        <Create {...props}>
            <SimpleForm validate={validateFields}>
                <TextInput source="name"/>
                { hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceInput label="CLIENT" source="brandId" reference="brands" allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput> : <TextInput source="brandId" defaultValue={authUser.brand.id} style={styles.hiddenField}/> }
                <TextInput label="DESCRIPTION" source="description"/>
                <NumberInput label="PRICE" source="price" defaultValue={1}/>
                <SelectInput label="REPEAT PERIOD" source="repeat" choices={priceRepeatChoices} defaultValue={"none"}/>
                <NumberInput label="DEPOSIT" source="deposit" defaultValue={0}/>
                <BooleanInput label="REQUIRES SHIPPING" source="requiresShipping" defaultValue={false}/>
                <NumberInput label="NUMBER IN STOCK" source="count" min="0" step="1" defaultValue={1}/>
                <SelectInput label="STATUS" source="status" defaultValue="Enabled" choices={statusChoices}/>
                <ImageInput multiple format={v => v && v.map(i => {return {src: i, title: ""}})} parse={v => v.map(i => i.src)} label="PHOTOS" source="imageRefs" >
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);
