import React from 'react';
import { TextInput } from 'react-admin'
import { Field } from 'redux-form';

const styles = {
    div: {
        display: 'inilne-grid',
        backgroundColor: '#eeeeee',
    },
    field: {
        margin: '1em'
    }
}

const AddressInput = ({source, name, ...props}) => (
    <div style={styles.div}>
        <Field name={source + ".name"} label="Name" component={TextInput} placeholder="" style={styles.field}/>
        <Field name={source + ".address1"} label="Address 1" component={TextInput} placeholder=""  style={styles.field}/>
        <Field name={source + ".address2"} label="Address 2" component={TextInput} placeholder=""  style={styles.field}/>
        <Field name={source + ".city"} label="City" component={TextInput} placeholder=""  style={styles.field}/>
        <Field name={source + ".state"} label="State" component={TextInput} placeholder=""  style={styles.field}/>
        <Field name={source + ".zip"} label="Zip Code" component={TextInput} placeholder="" />
    </div>
);

AddressInput.defaultProps = {
    addLabel: true,
    label: 'Address'
}

export default AddressInput;
