import React from 'react';
import {
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    ReferenceField,
    EditButton,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleForm,
    ShowButton,
    SimpleShowLayout,
    TextInput
} from 'react-admin';
import FullNameField from '../fields/FullNameField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import FilterUserInput from '../inputs/FilterUserInput'

import Icon from 'material-ui-icons/Block';
import DocumentTitle from 'react-document-title'

const BlockFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="User" source="fromUserID" reference="profiles" allowEmpty>
            <SelectInput optionText="displayName" />
        </ReferenceInput>
    </Filter>
);

export const BlockIcon = Icon

export const BlockList = (props) => (
    <DocumentTitle title="Binsto - Blocks">
        <List title="Blocked Users" {...props} filters={<BlockFilter />}>
            <Datagrid>
                <ReferenceField label="Blocking User" source="fromUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <ReferenceField label="Blocked User" source="toUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const BlockTitle = ({ record }) => {
    return <span>Blocked User</span>;
};

export const BlockShow = (props) => (
    <DocumentTitle title="Binsto - Blocked User">
        <Show title={<BlockTitle />} {...props}>
            <SimpleShowLayout>
                <ReferenceField label="Blocking User" source="fromUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <ReferenceField label="Blocked User" source="toUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const validateFields = (values, allValues) => {
    const errors = {};
    if (!values.fromUserID) {
        errors.fromUserID = ['You must specify a blocking user'];
    }
    if (!values.toUserID) {
        errors.toUserID = ['You must specify a blocked user'];
    }
    return errors
};

export const BlockEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Blocked User">
        <Edit title={<BlockTitle />} {...props}>
            <SimpleForm validate={validateFields}>
                <FilterUserInput source="fromUserID" label="Blocking User"/>
                <FilterUserInput source="toUserID" label="Blocked User"/>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const BlockCreate = (props) => (
    <DocumentTitle title="Binsto - Create Blocked User">
        <Create {...props}>
            <SimpleForm validate={validateFields}>
                <FilterUserInput source="fromUserID" label="Blocking User"/>
                <FilterUserInput source="toUserID" label="Blocked User"/>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);