import React from 'react';
import {
    DateField,
    BooleanField,
    BooleanInput,
    Filter,
    List,
    Datagrid,
    ReferenceField,
    TextField,
    EditButton,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextInput
} from 'react-admin';
import Icon from 'material-ui-icons/Inbox';
import FullNameField from '../fields/FullNameField'
import MessageAboutField from '../fields/MessageAboutField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'
import FilterUserInput from '../inputs/FilterUserInput'
import {authUser} from '../auth/firestoreAuthClient'
import { Rights, hasRight } from '../Roles'

const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <FilterUserInput source="fromUserID" label="From User"/>
        <BooleanInput source="opened" label="Opened"/>
    </Filter>
);

export const ReceivedMessagesIcon = Icon

export const ReceivedMessageList = ({ permissions, ...props }) => (
    <DocumentTitle title="Binsto - Received Messages">
        <List
            title="My Received Messages"
            {...props}
            filters={<MessageFilter/>}
            filter={{"toUserID" : authUser ? authUser.profile.uid : ""}}
        >
            <Datagrid>
                {hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceField linkType="show" label="From" source="fromUserID" reference="profiles">
                        <FullNameField />
                    </ReferenceField> : null }
                <ReferenceField linkType="show" label="From" source="fromUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <DateField label="Sent On" source="createdAt" />
                <TextField source="message"/>
                <MessageAboutField label="About"/>
                <BooleanField source="opened"/>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const MessageTitle = ({ record }) => {
    return <span>Message sent {record ? `"${record.createdAt}"` : ''}</span>;
};

export const ReceivedMessageShow = (props) => (
    <DocumentTitle title="Binsto - Show Message">
        <Show title={<MessageTitle />} {...props}>
            <SimpleShowLayout>
                <ReferenceField linkType="show" label="From" source="fromUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <ReferenceField linkType="show" label="To" source="toUserID" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                <DateField label="Sent On" source="createdAt" />
                <TextField source="message"/>
                <MessageAboutField label="About"/>
                <BooleanField source="opened"/>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);
