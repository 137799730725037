import React from 'react';
import pure from 'recompose/pure';
import DatabaseFirstImage from '../uicomponents/DatabaseFirstImage'

const MessageAboutField = ({ record = {}}) => {
    switch (record.aboutType) {
        case 0:
            return <span></span>
        case 1:
            return <span><a href={"/#/items/" + record.aboutID + "/show"}><DatabaseFirstImage reference="items" source={record.aboutID} /></a></span>
        case 2:
            return <span><DatabaseFirstImage reference="containers" source={record.aboutID} /></span>
        case 3:
            return <span><DatabaseFirstImage reference="storage" source={record.aboutID} /></span>
    }
}

const PureMessageAboutField = pure(MessageAboutField);

PureMessageAboutField.defaultProps = {
    addLabel: true
};

export default PureMessageAboutField;
