import React, { Component } from 'react';
import Card, { CardActions, CardContent } from 'material-ui/Card';
import { Button, showNotification as showNotificationAction, ViewTitle, UPDATE } from 'react-admin';
import TextField from 'material-ui/TextField';
import { push as pushAction } from 'react-router-redux';
import { connect } from 'react-redux'
import { CreateUser } from '../api/BackEndAPI.js'
import {authUser} from '../auth/firestoreAuthClient'
import restClient from '../restClient'

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = { emailFieldValue: '', passwordFieldValue: '' }
    }

    handleClick = () => {
        const { push, showNotification } = this.props;
        const _this = this
        CreateUser(this.state.emailFieldValue, this.state.passwordFieldValue).then(function(user) {
            showNotification("User successfully added")
            setTimeout( ()=> restClient(UPDATE, 'profiles', { id: user, data: { brand: authUser.brand.id }}), 1000)
            _this.setState({
                emailFieldValue: "",
                passwordFieldValue: ""
            })
        }).catch(function(error) {
                showNotification(error)
        });
    }

    getInitialState = () => {
        return {
            emailFieldValue: "",
            passwordFieldValue: ""
        }
    }

    handleEmailFieldChange = (event) => {
        this.setState({
            emailFieldValue: event.target.value
        });
    }

    handlePasswordFieldChange = (event) => {
        this.setState({
            passwordFieldValue: event.target.value
        });
    }

    render() {
        return (
            <Card>
                <ViewTitle title="Add a New User" />
                <CardContent>
                    <TextField
                        value={this.state.emailFieldValue}
                        fullWidth
                        label="Email Address"
                        onChange={this.handleEmailFieldChange}
                    />
                    <TextField
                        value={this.state.passwordFieldValue}
                        fullWidth
                        label="Password"
                        type="password"
                        onChange={this.handlePasswordFieldChange}
                    />
                </CardContent>
                <CardActions>
                    <Button label="Add User" onClick={this.handleClick}/>
                </CardActions>
            </Card>
        )
    }

}

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(AddUser);

