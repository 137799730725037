
/* eslint react/jsx-key: off */
import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Route } from 'react-router';

import { Admin, Resource, Delete } from 'react-admin'; // eslint-disable-line import/no-unresolved

import CustomRouteNoLayout from './customRouteNoLayout';
import CustomRouteLayout from './customRouteLayout';

import i18nProvider from './i18nProvider';
import { hot } from 'react-hot-loader'

import { RestClient, AuthClient } from './auth'
import { firebaseConfig} from "./config";
import FirebaseSignin from './FirebaseSignin'
import addUploadCapabilities from './addUploadCapabilities'

import FavIcon from './icons/favicon.ico'

const trackedResources = ['profiles', 'items', 'containers', 'storagelocations', 'categories', 'brands', 'messages', 'sentmessages', 'receivedmessages', 'accountsettings',
    'subscriptionplan', 'initialuserdata', 'orderitems', 'tags', 'history', 'blocks', 'labellayouts', 'subscriptionplan', 'orders', 'products', 'transactions', 'intro']

export const restClient = addUploadCapabilities(RestClient(trackedResources, firebaseConfig))

import { ItemList, ItemCreate, ItemEdit, ItemShow } from './viewers/Items';
import { ContainerList, ContainerCreate, ContainerEdit, ContainerShow } from './viewers/Containers';
import { StorageList, StorageCreate, StorageEdit, StorageShow } from './viewers/StorageLocations';
import { CategoryList, CategoryCreate, CategoryEdit, CategoryShow } from './viewers/Categories'
import { BrandList, BrandCreate, BrandEdit, BrandShow } from './viewers/Brands'
import { UserList, UserShow, UserEdit, UserCreate } from './viewers/Users'
import { MessageList, MessageCreate, MessageEdit, MessageShow } from './viewers/Messages'
import { SentMessageList, SentMessageCreate, SentMessageEdit, SentMessageShow } from './viewers/SentMessages'
import { ReceivedMessageList, ReceivedMessageCreate, ReceivedMessageEdit, ReceivedMessageShow } from './viewers/ReceivedMessages'
import { TagsList, TagsEdit, TagsCreate, TagsShow } from './viewers/Tags'
import { InitialUserDataList, InitialUserDataEdit, InitialUserDataCreate, InitialUserDataShow } from './viewers/InitialUserData'
import { BlockList, BlockEdit, BlockCreate, BlockShow } from './viewers/Blocks'
import { UserCustomizationsList, UserCustomizationsEdit, UserCustomizationsCreate, UserCustomizationsShow } from './viewers/UserCustomizations'
import { LabelLayoutsList, LabelLayoutsEdit, LabelLayoutsCreate, LabelLayoutsShow } from './viewers/LabelLayouts'
import { SubscriptionPlansList, SubscriptionPlansEdit, SubscriptionPlansShow, SubscriptionPlansCreate,  } from './viewers/SubscriptionPlans'
import { OrdersList, OrdersEdit, OrdersCreate, OrdersShow } from './viewers/Orders'
import { ProductsList, ProductsEdit, ProductsCreate, ProductsShow } from './viewers/Products'
import { TransactionsList, TransactionsEdit, TransactionsCreate, TransactionsShow } from './viewers/Transactions'
import { IntrosList, IntrosEdit, IntrosCreate, IntrosShow } from './viewers/Intros'
import { OrderItemsCreate, OrderItemsEdit } from "./viewers/OrderItems";
import Dashboard from './dashboard/Dashboard'
import Menu from './Menu'
import { Rights, hasRight } from './Roles'
import MessageBroadcast from './customPages/MessageBroadcast'
import SentMessages from './viewers/SentMessages'
import ReceivedMessages from './viewers/ReceivedMessages'

import AppBarTitle from './AppBarTitle'

const App = () =>
        <Admin
            menu={Menu}
            dashboard={Dashboard}
            loginPage={FirebaseSignin}
            authProvider={AuthClient}
            dataProvider={restClient}
            i18nProvider={i18nProvider}
            title={<AppBarTitle/>}
            locale="en"
            customRoutes={[
                <Route exact path="/broadcast" component={MessageBroadcast} />,
            ]}
        >
            <Resource
                name="orderitems"
                create={OrderItemsCreate}
                edit={OrderItemsEdit}
            />
            <Resource
                name="items"
                list={ItemList}
                create={ItemCreate}
                edit={ItemEdit}
                show={ItemShow}
            />
            <Resource
                name="containers"
                list={ContainerList}
                create={ContainerCreate}
                edit={ContainerEdit}
                show={ContainerShow}
            />
            <Resource
                name="storagelocations"
                list={StorageList}
                create={StorageCreate}
                edit={StorageEdit}
                show={StorageShow}
            />
            <Resource
                name="brands"
                edit={BrandEdit}
                list={BrandList}
                create={BrandCreate}
                show={BrandShow}
            />
            <Resource
                name="categories"
                list={CategoryList}
                create={CategoryCreate}
                edit={CategoryEdit}
                show={CategoryShow}
            />
            <Resource
                name="messages"
                list={MessageList}
                create={MessageCreate}
                edit={MessageEdit}
                show={MessageShow}
            />
            <Resource
                name="sentmessages"
                list={SentMessageList}
                create={SentMessageCreate}
                edit={SentMessageEdit}
                show={SentMessageShow}
            />
            <Resource
                name="receivedmessages"
                list={ReceivedMessageList}
                show={ReceivedMessageShow}
            />
            <Resource
                name="tags"
                list={TagsList}
                create={TagsCreate}
                edit={TagsEdit}
                show={TagsShow}
            />
            <Resource
                name="initialuserdata"
                list={InitialUserDataList}
                create={InitialUserDataCreate}
                edit={InitialUserDataEdit}
                show={InitialUserDataShow}
            />
            <Resource
                name="blocks"
                list={BlockList}
                create={BlockCreate}
                edit={BlockEdit}
                show={BlockShow}
            />
            <Resource
                name="orders"
                list={OrdersList}
                create={OrdersCreate}
                edit={OrdersEdit}
                show={OrdersShow}
            />
            <Resource
                name="products"
                list={ProductsList}
                create={ProductsCreate}
                edit={ProductsEdit}
                show={ProductsShow}
            />
            <Resource
                name="transactions"
                list={TransactionsList}
                create={TransactionsCreate}
                edit={TransactionsEdit}
                show={TransactionsShow}
            />
            <Resource
                name="history"
                list={UserCustomizationsList}
                create={UserCustomizationsCreate}
                edit={UserCustomizationsEdit}
                show={UserCustomizationsShow}
            />
            <Resource
                name="intro"
                list={IntrosList}
                create={IntrosCreate}
                edit={IntrosEdit}
                show={IntrosShow}
            />
            <Resource
                name="labellayouts"
                list={LabelLayoutsList}
                create={LabelLayoutsCreate}
                edit={LabelLayoutsEdit}
                show={LabelLayoutsShow}
            />
            <Resource
                name="subscriptionplan"
                list={SubscriptionPlansList}
                create={SubscriptionPlansCreate}
                edit={SubscriptionPlansEdit}
                show={SubscriptionPlansShow}
            />
            <Resource
                name="profiles"
                list={UserList}
                create={UserCreate}
                edit={UserEdit}
                show={UserShow}
            />
        </Admin>

export default hot(module)(App)
