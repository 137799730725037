import React from 'react';

const SingleImageField = ({ record  }) =>
    <span>
        <img src={record} alt="" />
    </span>;

SingleImageField.defaultProps = {
    addLabel: true
};

export default SingleImageField;
