import firebase from 'firebase'

const db = firebase.storage()

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

// upload a file or blob, returns the complete URL file path name in the resolve
const uploadMedia = mediaBase => filename => new Promise((resolve, reject) => {
    console.log("mediaBase", mediaBase, "filename", filename)
    var storageRef = firebase.storage().ref();
    var imageRef = storageRef.child(mediaBase + "/" + uuidv4())
    fetch(filename).then(function(response) {
        return response.arrayBuffer();
    }).then(function(data) {
        imageRef.put(new Blob([data])).then(function(snapshot) {
            resolve(snapshot.downloadURL)
        }).catch(function(error) { reject(error) });
    })

});

/*
 */
const addUploadCapabilities = requestHandler => (type, resource, params) => {
    if ((type === 'UPDATE' || type === 'CREATE') && (resource === 'items' || resource === 'containers' || resource === 'storagelocations' || resource === 'products')) {
        if (params.data.imageRefs && params.data.imageRefs.length) {
            // only freshly dropped pictures are instance of File
            const formerPictures = params.data.imageRefs.filter(p => !(p.startsWith("blob:")));
            const newPictures = params.data.imageRefs.filter(p => p.startsWith("blob:"));
            return Promise.all(newPictures.map(uploadMedia("media/" + (resource === "products" ? "products" : params.data._info.ownedBy ))))
                .then(transformedNewPictures => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        imageRefs: [...transformedNewPictures, ...formerPictures],
                    },
                }));
        }
    }
    // brands
    if ((type === 'UPDATE' || type === 'CREATE') && (resource === 'brands')) {
        if (params.data.logoURL && params.data.logoURL.startsWith("blob:")) {
            return Promise.all([uploadMedia("brands")(params.data.logoURL)])
                .then(transformedNewPicture => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        logoURL: transformedNewPicture[0],
                    },
                }));
        }
    }
    // intros
    if ((type === 'UPDATE' || type === 'CREATE') && (resource === 'intro')) {
        if (params.data.backgroundImage && params.data.backgroundImage.startsWith("blob:")) {
            return Promise.all([uploadMedia("intro")(params.data.backgroundImage)])
                .then(transformedNewPicture => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        backgroundImage: transformedNewPicture[0],
                    },
                }));
        }
    }
    return requestHandler(type, resource, params);
};

export default addUploadCapabilities;