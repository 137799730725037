import React from 'react';
import { CardActions } from 'material-ui/Card';
import {
    ImageField,
    ImageInput,
    Filter,
    List,
    ListButton,
    DeleteButton,
    Edit,
    Create,
    Datagrid,
    TextField,
    EditButton,
    ReferenceInput,
    RefreshButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    ShowButton,
    TextInput
} from 'react-admin';
import SingleImageField from '../fields/SingleImageField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'
import ColorInput from '../inputs/ColorInput'
import {authUser} from '../auth/firestoreAuthClient'
import { Rights, hasRight } from '../Roles'

const BrandFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput label="User" source="userId" reference="profiles" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

import Icon from '../icons/office.png'
import IconTint from '../IconTint'
export const BrandIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const BrandList = ({ permissions, ...props }) => {

    return hasRight(permissions, Rights.siteAdministration) ? <DocumentTitle title="Binsto - Clients">
        <List title="Clients" {...props} filters={<BrandFilter />}>
            <Datagrid>
                <TextField label="NAME" source="name" />
                <TextField label="SUBDOMAIN" source="subdomain" />
                <ImageField label="LOGO" source="logoURL" />
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle> : null
}



const BrandTitle = ({ record }) => {
    return <span>Client {record ? `"${record.name}"` : ''}</span>;
};

export const BrandShow = (props) => (
    <DocumentTitle title="Binsto - Show Client">
        <Show title={<BrandTitle />} {...props}>
            <SimpleShowLayout>
                <TextField label="NAME" source="name" />
                <TextField label="SUBDOMAIN" source="subdomain" />
                <TextField label="BRAND URL" source="brandURL"/>
                <TextField label="INVITATION URL" source="invitationURL"/>
                <TextField label="SUPPORT PHONE" source="supportPhone"/>
                <TextField label="SUPPORT EMAIL" source="supportEmail"/>
                <ImageField label="BRAND LOGO" source="logoURL" placeholder={<p>Drop your image file here or click to select one.</p>}>
                    <SingleImageField />
                </ImageField>
                <TextField label="ID" source="id" />
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const BrandEditActions = ({ resource, basePath, data }) => (
    <CardActions style={cardActionStyle}>
        {authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? <ShowButton basePath={basePath} record={data}/> : null}
        {authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? <ListButton basePath={basePath}/> : null}
        {authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? <DeleteButton resource={resource} basePath={basePath} record={data}/> : null}
        <RefreshButton/>
    </CardActions>
)

export const BrandEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Client">
        <Edit actions={<BrandEditActions/>} title={<BrandTitle />} {...props}>
            <SimpleForm>
                <TextInput label="NAME" source="name" />
                {authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? <TextInput label="SUBDOMAIN" source="subdomain" /> : <TextField label="SUBDOMAIN" source="subdomain" />}
                <TextInput label="BRAND URL" source="brandURL"/>
                {authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? <TextInput label="INVITATION URL" source="invitationURL"/> : <TextField label="INVITATION URL" source="invitationURL"/>}
                <TextInput label="SUPPORT PHONE" source="supportPhone"/>
                <TextInput label="SUPPORT EMAIL" source="supportEmail"/>
                <ColorInput label="PRIMARY COLOR" source="primaryColor"/>
                <ColorInput label="SECONDARY COLOR" source="secondaryColor"/>
                <ImageInput format={v => { return {src: v, title: ""}}} parse={v => v && v.src} label="BRAND LOGO" source="logoURL" >
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const BrandCreate = (props) => (
    <DocumentTitle title="Binsto - Create Client">
        <Create {...props}>
            <SimpleForm>
                <TextInput label="NAME" source="name" />
                <TextInput label="SUBDOMAIN" source="subdomain" />
                <TextInput label="BRAND URL" source="brandURL"/>
                <TextInput label="INVITATION URL" source="invitationURL"/>
                <TextInput label="SUPPORT PHONE" source="supportPhone"/>
                <TextInput label="SUPPORT EMAIL" source="supportEmail"/>
                <ColorInput label="PRIMARY COLOR" source="primaryColor"/>
                <ColorInput label="SECONDARY COLOR" source="secondaryColor"/>
                <ImageInput format={v => { return {src: v, title: ""}}} parse={v => v && v.src} label="BRAND LOGO" source="logoURL" >
                    <ImageField source="src" title="title"/>
                </ImageInput>

            </SimpleForm>
        </Create>
    </DocumentTitle>
);