import React from 'react';
import {
    NumberInput,
    ImageInput,
    NumberField,
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    ImageField,
    TextField,
    EditButton,
    LongTextInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    SimpleForm,
    TextInput
} from 'react-admin';
import SingleImageField from '../fields/SingleImageField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import { ScalableImageField } from '../fields/ScalableImageField'

import Icon from '../icons/introScreenIcon.png'
import IconTint from '../IconTint'
import DocumentTitle from 'react-document-title'
import {authUser} from '../auth/firestoreAuthClient'
import { Rights, hasRight } from '../Roles'

export const IntrosIcon = (props) => (<IconTint {...props} src={Icon}/>)

const IntrosFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Client" source="brandId" reference="brands">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const IntrosList = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Intro Screens">
        <List
            filters={hasRight(permissions, Rights.siteAdministration) ? <IntrosFilter/> : null}
            title="Intro Screens" {...props}
            filter={hasRight(permissions, Rights.siteAdministration) ? null : { "brandId": authUser? authUser.brand.id : ""}}
        >
            <Datagrid>
                { hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceField linkType="show" label="CLIENT" source="brandId" reference="brands">
                    <TextField source="name" />
                </ReferenceField>
                    : null }
                <ScalableImageField label="BACKGROUND IMAGE" source="backgroundImage" sortable={false} elStyle={{height: "48px", padding: "4px"}}/>
                <NumberField label="SEQUENCE" source="sequence" />
                <TextField label="TITLE" source="title" />
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const IntrosTitle = ({ record }) => {
    return <span>Intro Screen sequence {record ? `"${record.sequence}"` : ''}</span>;
};

export const IntrosShow = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Show Intro Screen">
        <Show title={<IntrosTitle />} {...props}>
            <SimpleShowLayout>
                { hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceField linkType="show" label="CLIENT" source="brandId" reference="brands">
                    <TextField source="name" />
                </ReferenceField>
                    : null }
                <NumberField label="SEQUENCE" source="sequence" />
                <TextField label="TITLE" source="title" />
                <TextField label="TEXT" source="text" />
                <ImageField label="BACKGROUND IMAGE" source="backgroundImage" placeholder={<p>Drop your image file here or click to select one.</p>}>
                    <SingleImageField />
                </ImageField>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

const styles = {
    hiddenField: {
        display: 'none'
    }
}

export const validateFields = (values, allValues) => {
    const errors = {};
    if (!values.sequence) {
        errors.sequence = ['You must specify a sequence number'];
    }
    if (!values.description && !values.text && !values.backgroundImage) {
        errors.description = ['You must specify a title, text or background image']
    }
    return errors
};

export const IntrosEdit = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Edit Intro Screen">
        <Edit title="Edit Intro Screen" {...props}>
            <SimpleForm validate={validateFields}>
                { hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceInput label="CLIENT" source="brandId" reference="brands">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                    : <TextInput source="brandId" defaultValue={authUser ? authUser.brand.id : ""} style={styles.hiddenField}/> }
                <NumberInput source="sequence" />
                <TextInput source="title" />
                <LongTextInput source="text" />
                <ImageInput format={v => { return {src: v, title: ""}}} parse={v => v && v.src} label="BACKGROUND IMAGE" source="backgroundImage" >
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const IntrosCreate = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Create Intro Screen">
        <Create title="Create Intro Screen" {...props}>
            <SimpleForm validate={validateFields}>
                { hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceInput label="CLIENT" source="brand" reference="brands">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                    : <TextInput source="brandId" defaultValue={authUser.brand.id} style={styles.hiddenField}/> }
                <NumberInput source="sequence" />
                <TextInput source="title" />
                <LongTextInput source="text" />
                <ImageInput format={v => { return {src: v, title: ""}}} parse={v => v && v.src} label="BACKGROUND IMAGE" source="backgroundImage" >
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);

