import React from 'react';
import Chip from 'material-ui/Chip';

const styles = {
    main: { display: 'flex', flexWrap: 'wrap' },
    chip: { margin: 4 }
};

const TagsField = ({ record  }) =>
    <span style={styles.main}>
        {(record.tags ||[]).map((tag, index) => (
            <Chip key={index} style={styles.chip} label={tag}/>
        ))}
    </span>;

export default TagsField;
