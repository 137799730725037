// in src/Dashboard.js
import React, { Component } from 'react';
import { AppBarMobile, GET_LIST } from 'react-admin';
import withWidth from 'material-ui/utils/withWidth';
import UserCount from './UserCount'
import ItemCount from './ItemCount'
import BinCount from './BinCount'
import StorageCount from './StorageCount'
import Orders from './Orders'
import BlockedUsers from './BlockedUsers'
import NewUsers from './NewUsers'
import Welcome from './Welcome'
import restClient from '../restClient'
import DocumentTitle from 'react-document-title'
import { Rights, hasRight } from '../Roles'
import {authUser} from '../auth/firestoreAuthClient'

const styles = {
    welcome: { marginBottom: '2em' },
    flex: { display: 'flex', marginTop: '2em' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em' },
};

class Dashboard extends Component {

    state = {};

    componentDidMount() {

        const d = new Date();
        d.setDate(d.getDate() - 30);
        var baseParams = {
            pagination: { page: 1, perPage: 0 },
            sort: { field: '_info.createdAt', order: 'DESC' }
        }

        var profileParams = Object.assign({}, baseParams)
        if (authUser && !hasRight(authUser.profile.role, Rights.siteAdministration)) {
            profileParams["filter"] = { brand: authUser.brand.id }
        }

        var ordersParams = Object.assign({}, baseParams)
        if (authUser && !hasRight(authUser.profile.role, Rights.siteAdministration)) {
            ordersParams["filter"] = { brandId: authUser.brand.id }
        }

        restClient(GET_LIST, 'profiles', profileParams)
            .then(response => response.data)
            .then(response => {
                const userIds = response.map(profile => profile.uid)
                restClient(GET_LIST, 'items', baseParams).then(response => response.data.filter(item => userIds.indexOf(item._info ? item._info.ownedBy : "") >= 0)).then(response => { this.setState({itemCount: response.length.toString()})} )

                restClient(GET_LIST, 'containers', baseParams).then(response => response.data.filter(item => userIds.indexOf(item._info ? item._info.ownedBy : "") >= 0)).then(response => { this.setState({binCount: response.length.toString()})} )

                restClient(GET_LIST, 'storagelocations', baseParams).then(response => response.data.filter(item => userIds.indexOf(item._info ? item._info.ownedBy : "") >= 0)).then(response => { this.setState({storageCount: response.length.toString()})} )

                restClient(GET_LIST, 'orders', ordersParams).then(response => { this.setState({orders: response.data })} )

                restClient(GET_LIST, 'blocks', baseParams).then(response => response.data.filter(item => userIds.indexOf(item.fromUserID) >= 0)).then(response => response.filter(item => userIds.indexOf(item.toUserID) >= 0)).then(response => { this.setState({ blockedUsers: response.data })} )

                this.setState({allUsers: response, newUsers: response.slice(0,10), userCount: response.length.toString()})
            })


    }

    render() {
        const {
            userCount,
            itemCount,
            binCount,
            storageCount,
            orders,
            blockedUsers,
            newUsers,
            allUsers,

        } = this.state;

        const {width, permissions, ...props} = this.props;

        return (

            <DocumentTitle title="Binsto - Dashboard">
                <div>
                    {width === 1 && <AppBarMobile title="Binsto Admin"/>}
                    <Welcome style={styles.welcome}/>
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.flex}>
                                { authUser && hasRight(authUser.profile.role, Rights.userManagement) ?
                                <UserCount value={userCount}/> : null }
                                <ItemCount value={itemCount}/>
                            </div>
                            <div style={styles.flex}>
                                <BinCount value={binCount}/>
                                <StorageCount value={storageCount}/>
                            </div>
                            <div style={styles.flex}>
                                <Orders values={orders}/>
                            </div>
                        </div>
                        { authUser && hasRight(authUser.profile.role, Rights.userManagement) ?
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <BlockedUsers blocks={blockedUsers} users={allUsers}/>
                                <NewUsers users={newUsers}/>
                            </div>
                        </div> : null }
                    </div>
                </div>
            </DocumentTitle>
        )
    }
}

export default withWidth()(Dashboard);