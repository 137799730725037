import React from 'react';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase'
import { firebaseConfig} from "./config";

firebase.initializeApp(firebaseConfig);

export const fbAuth = firebase.auth()

const signinStyles = {
    div: {
        margin: "0 auto",
        width: "100%",
        paddingTop: "50px"
    },
    img: {
        display: "block",
        margin: "auto",
        paddingBottom: "50px"
    }
}

class SigninScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = { logoURL: '' }
    }

    componentDidMount() {
        this.unregisterAuthObserver = fbAuth.onAuthStateChanged(
            (user) =>  { if (user) { this.props.userLogin(fbAuth.currentUser) }}
        );
        let subdomain = window.location.host.split(".")[0]
        const db = firebase.firestore()
        let query = db.collection('brands').where("subdomain", "==", subdomain)
        query.get().then(snapshot => {
            if (snapshot.docs.length > 0)
                this.setState({ logoURL: snapshot.docs[0].data().logoURL} )
            else
                this.setState({ logoURL: 'https://firebasestorage.googleapis.com/v0/b/binsto-2a322.appspot.com/o/brands%2FBinStoWordLogo-250.png?alt=media&token=d7373b0b-cfa7-4c93-9496-32d47c5e800e'} )
        })

    }

    componentWillUnmount() {
        if (this.unregisterAuthObserver)
            this.unregisterAuthObserver();
    }

    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: true
            },
            firebase.auth.PhoneAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccess: () => false
        }
    };

    render() {

        return (
            <div style={signinStyles.div}>
                <img style={signinStyles.img} src={this.state.logoURL} alt=''/>
                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={fbAuth}/>
            </div>
        );
    }
}

export default connect(undefined, { userLogin })(SigninScreen)
