import React from 'react';
import {
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    EditButton,
    ShowButton,
    SimpleShowLayout,
    SimpleForm
} from 'react-admin';
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'

const InitialUserDataFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

import Icon from '../icons/initialDataIcon.png'
import IconTint from '../IconTint'
export const InitialUserDataIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const InitialUserDataList = (props) => (
    <DocumentTitle title="Binsto - Initial User Data">
        <List title="Initial User Data" {...props} filters={<InitialUserDataFilter />}>
            <ActionToolbar>
                <EditButton />
                <ShowButton />
            </ActionToolbar>
        </List>
    </DocumentTitle>
);

const InitialUserDataTitle = ({ record }) => {
    return <span>Custom User Data for {record ? `"${record.name}"` : ''}</span>;
};

export const InitialUserDataShow = (props) => (
    <DocumentTitle title="Binsto - Show Initial User Data">
        <Show title={<InitialUserDataTitle />} {...props}>
            <SimpleShowLayout>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const InitialUserDataEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Initial User Data">
        <Edit title={<InitialUserDataTitle />} {...props}>
            <SimpleForm>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const InitialUserDataCreate = (props) => (
    <DocumentTitle title="Binsto - Create Initial User Data">
        <Create {...props}>
            <SimpleForm>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);