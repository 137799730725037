import React from 'react';
import {
    AutocompleteInput,
    BulkActions,
    BulkDeleteAction,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EditButton,
    Filter,
    FormTab,
    List,
    ImageInput,
    ImageField,
    LongTextInput,
    NumberField,
    NumberInput,
    Responsive,
    ReferenceArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    SelectArrayInput,
    ReferenceField,
    ReferenceInput,
    RichTextField,
    SaveButton,
    SelectField,
    SelectInput,
    Show,
    ShowButton,
    SimpleList,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    Toolbar,
    translate,
    WithPermissions
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import _ from 'lodash'
import RichTextInput from 'ra-input-rich-text';
import Chip from 'material-ui/Chip';
import { InputAdornment } from 'material-ui/Input';
import { withStyles } from 'material-ui/styles';
import BookIcon from 'material-ui-icons/Book';
import SearchIcon from 'material-ui-icons/Search';
export const ListIcon = BookIcon;
import ResetViewsAction from '../ResetViewsAction';
import TagsField  from '../fields/TagsField'
import FirstImageField from '../fields/FirstImageField'
import FullNameField from '../fields/FullNameField'
import GoogleMapsField from '../fields/GoogleMapsField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import FilterUserInput from '../inputs/FilterUserInput'
import { Rights, hasRight } from '../Roles'
import DocumentTitle from 'react-document-title'
import {authUser} from '../auth/firestoreAuthClient'
import SingleImageField from '../fields/SingleImageField'
import { validateNameAndOwner } from '../fields/Validations'

const QuickFilter = translate(({ label, translate }) => (
    <Chip style={{ marginBottom: 8 }} label={translate(label)} />
));

const ItemFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
            label="post.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
        { hasRight(permissions, Rights.siteAdministration) ?
            <FilterUserInput source="_info.ownedBy" label="Owner"/> : null }
        <BooleanInput
            label="Is Private"
            source="isPrivate"
        />
        <BooleanInput
            label="For Sale"
            source="isForSale"
            defaultValue
        />
        <BooleanInput
            label="Is Sample"
            source="_info.isSample"
        />
    </Filter>
);

const styles = {
    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    publishedAt: { fontStyle: 'italic' },
};

const ItemListBulkActions = props => (
    <BulkActions {...props}>
        <ResetViewsAction label="simple.action.resetViews" />
        <BulkDeleteAction />
    </BulkActions>
);

import Icon from '../icons/itemIcon.png'
import IconTint from '../IconTint'
export const ItemIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const ItemList = withStyles(styles)(({ classes, permissions, ...props }) => (
    <DocumentTitle title="Binsto - Items">
        <List
            {...props}
            bulkActions={<ItemListBulkActions />}
            filters={<ItemFilter permissions={permissions}/>}
            sort={{ field: '_info.createdAt', order: 'DESC' }}
            filter={hasRight(permissions, Rights.siteAdministration) ? null : { "_info": {ownedBy: authUser ? authUser.profile.uid : ""} } }
            title="Items"
        >
            <Responsive
                small={
                    <SimpleList
                        primaryText={record => record.name}
                        rightIcon={record => <FirstImageField record={record} source="imageRefs" label="" sortable={false}/>}
                        secondaryText={record =>
                            new Date(record._info.createdAt).toLocaleDateString()}
                    />
                }
                medium={
                    <Datagrid>
                        <TextField label="NAME" source="name" cellClassName={classes.title} />
                        {hasRight(permissions, Rights.siteAdministration) ?
                            <ReferenceField linkType="show" label="OWNED BY" source="_info.ownedBy" reference="profiles">
                                <FullNameField />
                            </ReferenceField>
                            : null}
                        <DateField
                            label="CREATED AT"
                            source="_info.createdAt"
                            cellClassName={classes.publishedAt}
                        />
                        <TagsField label="TAGS"/>
                        <FirstImageField source="imageRefs" label="" sortable={false}/>
                        <ActionToolbar>
                            <EditButton />
                            <ShowButton />
                        </ActionToolbar>
                    </Datagrid>
                }
            />
        </List>
    </DocumentTitle>
));

const ItemTitle = translate(({ record, translate }) => (
    <span>
        {record ? record.name : ''}
    </span>
));

const getDefaultDate = () => new Date();

export const ItemCreate = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Create Item">
        <Create {...props}>
            <TabbedForm validate={validateNameAndOwner}>
                <FormTab label="INFO">
                    <ImageInput multiple format={v => v && v.map(i => {return {src: i, title: ""}})} parse={v => v.map(i => i.src)} label="PHOTOS" source="imageRefs" >
                        <ImageField source="src" title="title"/>
                    </ImageInput>
                    <TextInput source="name" />
                    <LongTextInput source="description" />
                    {hasRight(permissions, Rights.siteAdministration) ?
                        <FilterUserInput source="_info.ownedBy" label="Owner"/> : null}
                    <ReferenceArrayInput label="TAGS" reference="tags" source="tags">
                        <SelectArrayInput>
                            <ChipField source="name" />
                        </SelectArrayInput>
                    </ReferenceArrayInput>
                    <BooleanInput defaultValue={false} label="Private" source="isPrivate"/>
                    <BooleanInput defaultValue={false} label="Sample" source="_info.isSample"/>
                </FormTab>
                <FormTab label="Location">
                    <ReferenceInput label="Bin" source="containerRef" reference="containers" allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label="Storage Location" source="storageRef" reference="storagelocations" allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <GoogleMapsField label="Geo Location" source="_info.geoLocation" />
                </FormTab>
                <FormTab label="SALE">
                    <SelectInput defaultValue="Excellent" label="Condition" source="condition" choices={[
                        { id: 'Excellent', name: 'Excellent' },
                        { id: 'Very Good', name: 'Very Good' },
                        { id: 'Good', name: 'Good' },
                        { id: 'Fair', name: 'Fair' },
                        { id: 'Poor', name: 'Poor' }
                    ]} />
                    <NumberInput defaultValue="1.00" label="Sale Price" source="value" />
                    <SelectInput label="Sale Status" source="sellStatus" choices={[
                        { id: 'Not for Sale', name: 'Not for Sale' },
                        { id: 'Make an offer', name: 'Make an Offer' },
                        { id: 'For Sale', name: 'For Sale' },
                        { id: 'Sold', name: 'Sold' }
                    ]} />
                </FormTab>
            </TabbedForm>
        </Create>
    </DocumentTitle>
);

export const ItemEdit = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Edit Item">
        <Edit title={<ItemTitle />} {...props}>
            <TabbedForm validate={validateNameAndOwner}>
                <FormTab label="INFO">
                    <ImageInput multiple format={v => v && v.map(i => {return {src: i, title: ""}})} parse={v => v.map(i => i.src)} label="PHOTOS" source="imageRefs" >
                        <ImageField source="src" title="title"/>
                    </ImageInput>
                    <TextInput source="name" />
                    <LongTextInput source="description" />
                    {hasRight(permissions, Rights.siteAdministration) ?
                        <ReferenceInput label="OWNED BY" source="_info.ownedBy" reference="profiles">
                            <FullNameField />
                        </ReferenceInput> : null }
                    <ReferenceArrayInput label="TAGS" reference="tags" source="tags">
                        <SelectArrayInput>
                            <ChipField source="name" />
                        </SelectArrayInput>
                    </ReferenceArrayInput>
                    <BooleanInput label="Private" source="isPrivate"/>
                    <BooleanInput defaultValue={false} label="Sample" source="_info.isSample"/>
                </FormTab>
                <FormTab label="Location">
                    <ReferenceInput label="Bin" source="containerRef" reference="containers" allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label="Storage Location" source="storageRef" reference="storagelocations" allowEmpty>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <GoogleMapsField label="Geo Location" source="_info.geoLocation" />
                </FormTab>
                <FormTab label="SALE">
                    <SelectInput label="Condition" source="condition" choices={[
                        { id: 'Excellent', name: 'Excellent' },
                        { id: 'Very Good', name: 'Very Good' },
                        { id: 'Good', name: 'Good' },
                        { id: 'Fair', name: 'Fair' },
                        { id: 'Poor', name: 'Poor' }
                    ]} />
                    <NumberInput defaultValue="1.00" label="Sale Price" source="value" />
                    <SelectInput defaultValue="Not for Sale" label="Sale Status" source="sellStatus" choices={[
                        { id: 'Not for Sale', name: 'Not for Sale' },
                        { id: 'Make an offer', name: 'Make an Offer' },
                        { id: 'For Sale', name: 'For Sale' },
                        { id: 'Sold', name: 'Sold' }
                    ]} />
                </FormTab>
                <FormTab label="STATS">
                    <DateInput label="CREATED AT" source="_info.createdAt" options={{ }}/>
                    <DateInput label="UPDATED AT" source="_info.updatedAt" />
                    <DateInput label="VIEWED AT" source="_info.viewedAt" />
                </FormTab>
            </TabbedForm>
        </Edit>
    </DocumentTitle>
);

export const ItemShow = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Show Item">
        <Show title={<ItemTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="INFO">
                    <FirstImageField source="imageRefs" label="Photos" count="1000" sortable={false}/>
                    <TextField source="name" />
                    <TextField source="description" />
                    {hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceField linkType="show" label="OWNED BY" source="_info.ownedBy" reference="profiles">
                        <FullNameField />
                    </ReferenceField> : null }
                    <ReferenceArrayField label="TAGS" reference="tags" source="tags">
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <NumberField label="Item Count" source="count"  />

                    <BooleanField label="Private" source="isPrivate"/>
                    <BooleanField label="Sample" source="_info.isSample"/>
                </Tab>
                <Tab label="Location">
                    <ReferenceField label="Bin" source="containerRef" reference="containers" allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Storage Location" source="storageRef" reference="storagelocations" allowEmpty>
                        <TextField source="name" />
                    </ReferenceField>
                    <GoogleMapsField label="Geo Location" source="_info.geoLocation" />
                </Tab>
                <Tab label="SALE">
                    <SelectField label="Condition" source="condition" choices={[
                        { id: 'Excellent', name: 'Excellent' },
                        { id: 'Very Good', name: 'Very Good' },
                        { id: 'Good', name: 'Good' },
                        { id: 'Fair', name: 'Fair' },
                        { id: 'Poor', name: 'Poor' }
                    ]} />
                    <NumberField label="Sale Price" source="value"  />
                    <SelectField label="Sale Status" source="sellStatus" choices={[
                        { id: 'Not for Sale', name: 'Not for Sale' },
                        { id: 'Make an offer', name: 'Make an Offer' },
                        { id: 'For Sale', name: 'For Sale' },
                        { id: 'Sold', name: 'Sold' }
                    ]} />
                </Tab>
                <Tab label="STATS">
                    {hasRight(permissions, Rights.siteAdministration) ?
                        <TextField label="ID" source="id"/>
                        : null }
                    <DateField label="CREATED AT" source="_info.createdAt" options={{ }}/>
                    <DateField label="UPDATED AT" source="_info.updatedAt" />
                    <DateField label="VIEWED AT" source="_info.viewedAt" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    </DocumentTitle>
);
