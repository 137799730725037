import React from 'react';
import {
    NumberInput,
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    EditButton,
    NumberField,
    Show,
    SimpleForm,
    ShowButton,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'

const LabelLayoutsFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

import Icon from '../icons/layoutIcon.png'
import IconTint from '../IconTint'
export const LabelLayoutsIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const LabelLayoutsList = (props) => (
    <DocumentTitle title="Binsto - Label Layouts">
        <List title="Label Layouts" {...props} filters={<LabelLayoutsFilter />}>
            <Datagrid>
                <TextField source="title"/>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const LabelLayoutsTitle = ({ record }) => {
    return <span>Custom User Data for {record ? `"${record.title}"` : ''}</span>;
};

const leftStyle = {
    display: 'inline-block', marginLeft: 16
}

const rightStyle = {
    display: 'inline-block', marginLeft: 16
}

export const LabelLayoutsShow = (props) => (
    <DocumentTitle title="Binsto - Show Label Layout">
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="title"/>
                <NumberField label="Number Across" source="numberAcross" style={leftStyle}/>
                <NumberField label="Number Down" source="numberDown" style={rightStyle}/>
                <NumberField label="Label Width" source="labelWidth" style={leftStyle}/>
                <NumberField label="Label Height" source="labelHeight" style={rightStyle}/>
                <NumberField label="Page Height" source="pageHeight" style={leftStyle}/>
                <NumberField label="Page Width" source="pageWidth" style={rightStyle}/>
                <NumberField label="Vertical Pitch" source="verticalPitch" style={leftStyle}/>
                <NumberField label="Horizontal Pitch" source="horizontalPitch"style={rightStyle}/>
                <NumberField label="Top Margin" source="topMargin" style={leftStyle}/>
                <NumberField label="Side Margin" source="sideMargin"style={rightStyle}/>
            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const LabelLayoutsEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Label Layout">
        <Edit title={<LabelLayoutsTitle />} {...props}>
            <SimpleForm>
                    <TextInput source="title"/>
                    <NumberInput label="Number Across" source="numberAcross" style={leftStyle}/>
                    <NumberInput label="Number Down" source="numberDown" style={rightStyle}/>
                    <NumberInput label="Label Width" source="labelWidth" style={leftStyle}/>
                    <NumberInput label="Label Height" source="labelHeight" style={rightStyle}/>
                    <NumberInput label="Page Height" source="pageHeight" style={leftStyle}/>
                    <NumberInput label="Page Width" source="pageWidth" style={rightStyle}/>
                    <NumberInput label="Vertical Pitch" source="verticalPitch" style={leftStyle}/>
                    <NumberInput label="Horizontal Pitch" source="horizontalPitch"style={rightStyle}/>
                    <NumberInput label="Top Margin" source="topMargin" style={leftStyle}/>
                    <NumberInput label="Side Margin" source="sideMargin"style={rightStyle}/>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const LabelLayoutsCreate = (props) => (
    <DocumentTitle title="Binsto - Create Label Layout">
        <Create {...props}>
            <SimpleForm>
                <TextInput source="title"/>
                <NumberInput label="Number Across" source="numberAcross" style={leftStyle}/>
                <NumberInput label="Number Down" source="numberDown" style={rightStyle}/>
                <NumberInput label="Label Width" source="labelWidth" style={leftStyle}/>
                <NumberInput label="Label Height" source="labelHeight" style={rightStyle}/>
                <NumberInput label="Page Height" source="pageHeight" style={leftStyle}/>
                <NumberInput label="Page Width" source="pageWidth" style={rightStyle}/>
                <NumberInput label="Vertical Pitch" source="verticalPitch" style={leftStyle}/>
                <NumberInput label="Horizontal Pitch" source="horizontalPitch"style={rightStyle}/>
                <NumberInput label="Top Margin" source="topMargin" style={leftStyle}/>
                <NumberInput label="Side Margin" source="sideMargin"style={rightStyle}/>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);