import React from 'react';
import Card, { CardHeader } from 'material-ui/Card';
import List, { ListItem } from 'material-ui/List';
import { BlockIcon } from '../viewers/Blocks'
import FullNameField from '../fields/FullNameField';
import _ from 'lodash'

const tintColor = "#aba000"

const styles = {
    card: { borderLeft: 'solid 4px ' + tintColor, flex: '1', marginRight: '1em', textDecoration: "none" },
    icon: { float: 'right', width: 64, height: 64, padding: 16, color: tintColor },
};

export default ({ blocks=[], users=[] }) => {
    const user = (record) => _.find(users, { uid: record.toUserID })
    return <Card style={styles.card}>
        <BlockIcon style={styles.icon}/>
        <CardHeader title="" subheader="Recent Blocked Users"/>
        <List>
            {blocks.map(record =>
                <ListItem button component="a" href={`#/profiles/${user(record).uid}/show`} key={record.id}>
                        <FullNameField record={user(record)}/>
                    </ListItem>
                )}
        </List>

    </Card>
};
