import React from 'react';
import {
    NumberInput,
    CreateButton,
    DeleteButton,
    ReferenceInput,
    DateField,
    TextInput,
    TextField,
    SelectInput,
    NumberField,
    ReferenceField,
    EditButton,
    ShowButton,
    DateInput,
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    SelectField,
    SimpleForm
} from 'react-admin';
import FullNameField from '../fields/FullNameField'
import AddressInput from '../inputs/AddressInput'
import AddressField from '../fields/AddressField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'
import FilterUserInput from '../inputs/FilterUserInput'
import {authUser} from '../auth/firestoreAuthClient'
import { Rights, hasRight } from '../Roles'

export const statusChoices = [ {id: 'cart', name: "Shopping Cart"}, {id: 'ordered', name: "Ordered"},
    {id: 'shipped', name: "Shipped"}, {id: 'delivered', name: "Delivered"}, , {id: 'returned', name: "Returned"} ]

const OrdersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <SelectInput label="Order Status" source="status" choices={statusChoices}/>
        <ReferenceInput label="Client" source="brandId" reference="brands">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

import Icon from '../icons/orderIcon.png'
import IconTint from '../IconTint'
export const OrdersIcon = (props) => (<IconTint {...props} src={Icon}/>)

const styles = {
    hiddenField: {
        display: 'none'
    }
}

export const OrdersList = ({ permissions, ...props }) => (
    <DocumentTitle title="Binsto - Orders">
        <List
            title="Orders" {...props}
            filters={<OrdersFilter/>}
            filter={hasRight(permissions, Rights.siteAdministration) ? null : { "brandId": authUser? authUser.brand.id : "" }}
        >
            <Datagrid>
                <DateField label="DATE CREATED" source="_info.createdAt"/>
                <ReferenceField linkType="show" linkType="show" label="USER" source="_info.ownedBy" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                { hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceField label="CLIENT" source="brandId" reference="brands">
                    <TextField source="name" />
                </ReferenceField> : null }
                <NumberField label="# ITEMS" source="itemCount"/>
                <NumberField label="TOTAL" source="totalAmount" options={{ style: 'currency', currency: 'USD' }} />
                <SelectField label="STATUS" source="status" choices={statusChoices}/>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const OrdersTitle = ({ record }) => {
    return <span>Order Details</span>;
};

export const OrdersShow = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Show Order">
        <Show title={<OrdersTitle />} {...props}>
            <SimpleShowLayout>
                <DateField label="Date Created" source="_info.createdAt"/>
                <ReferenceField linkType="show" label="USER" source="_info.ownedBy" reference="profiles">
                    <FullNameField />
                </ReferenceField>
                { hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceField label="CLIENT" source="brandId" reference="brands">
                    <TextField source="name" />
                </ReferenceField>
                    : null }
                <AddressField label="DELIVER TO" source="deliverTo"/>
                <SelectField source="status" choices={statusChoices}/>

                <ReferenceManyField label="ITEMS" reference="orderitems" target="orderId">
                    <Datagrid>
                        <ReferenceField label="NAME" source="productId" reference="products">
                            <TextField source="name" />
                        </ReferenceField>
                        <NumberField label="COUNT" source="count"/>
                        <ReferenceField label="PRICE" source="productId" reference="products">
                            <NumberField source="price" options={{ style: 'currency', currency: 'USD' }} />
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>

            </SimpleShowLayout>
        </Show>
    </DocumentTitle>
);

export const validateFields = (values, allValues) => {
    const errors = {};
    if (!values.productId) {
        errors.productId = ['You must specify a product'];
    }
    if (!values.count) {
        errors.count = ['You must specify a count'];
    }
    if (!values.brandId) {
        errors.brandId = ['You must specify a client'];
    }
    if (!values._info || !values._info.ownedBy) {
        _.set(errors, '_info.ownedBy', ['You must specify an order owner']);
    }
    if (!values._info || !values._info.createdAt) {
        _.set(errors, '_info.createdAt', ['You must specify an order date']);
    }

    return errors
};


export const OrdersEdit = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Edit Order">
        <Edit title={<OrdersTitle />} {...props}>
            <SimpleForm validate={validateFields}>
                <DateInput label="Date Created" source="_info.createdAt"/>
                <FilterUserInput source="_info.ownedBy" label="Owner"/>
                { hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceInput label="Client" source="brandId" reference="brands">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                    : null }
                <AddressInput label="Send To" source="deliverTo"/>
                <SelectInput source="status" choices={statusChoices}/>
                <ReferenceManyField label="ITEMS" reference="orderitems" target="orderId">
                    <Datagrid>
                        <ReferenceField label="NAME" source="productId" reference="products">
                            <TextField source="name" />
                        </ReferenceField>
                        <NumberField label="COUNT" source="count"/>
                        <ReferenceField label="PRICE" source="productId" reference="products">
                            <NumberField source="price" options={{ style: 'currency', currency: 'USD' }} />
                        </ReferenceField>
                        <DeleteButton/>
                        <CreateButton/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>

            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const OrdersCreate = ({ permissions, ...props}) => (
    <DocumentTitle title="Binsto - Create Order">
        <Create {...props}>
            <SimpleForm validate={validateFields}>
                <DateInput label="Date Created" source="_info.createdAt"/>
                <FilterUserInput source="_info.ownedBy" label="Owner"/>
                { hasRight(permissions, Rights.siteAdministration) ?
                <ReferenceInput label="Client" source="brandId" reference="brands">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                    : <TextInput source="brandId" defaultValue={authUser.brand.id} style={styles.hiddenField}/>}
                <AddressInput label="Send To" source="deliverTo"/>
                <SelectInput defaultValue="ordered" source="status" choices={statusChoices}/>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);