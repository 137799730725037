import React, { Children, cloneElement } from 'react';
import { withStyles } from 'material-ui/styles';

const ActionToolbar = withStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
    },
})(({ classes, children, ...props }) => (
    <div className={classes.toolbar}>
        {Children.map(children, button => cloneElement(button, props))}
    </div>
));

export default ActionToolbar