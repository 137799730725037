import React from 'react';
import Avatar from 'material-ui/Avatar';
import Person from 'material-ui-icons/Person';

const style = { verticalAlign: 'middle' };
const iconStyle = {  color: 'white', backgroundColor: 'gray'}

const AvatarField = ({ record, size }) => {
    return record.imageURL ? <Avatar src={record.imageURL} style={iconStyle} size={size}/> : <Avatar><Person/></Avatar>
}

AvatarField.defaultProps = {
    size: 25,
};

export default AvatarField;
