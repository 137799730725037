import React from 'react';
import {authUser} from '../auth/firestoreAuthClient'

import {
    AutocompleteInput,
    ReferenceInput
} from 'react-admin'

const FilterUserInput  = ({ reference, filter=filter={ brand: authUser ? authUser.brand.id : ""}, sort={ field: 'displayName', order: 'ASC' },  ...props }) =>
    <ReferenceInput {...props} reference="profiles" filter={filter} sort={sort} perPage={10000} allowEmpty>
        <AutocompleteInput optionText={choice => (choice.displayName ? choice.displayName + " - " : "") + choice.email } optionValue="uid"/>
    </ReferenceInput>

FilterUserInput.defaultProps = {
    addField: true, // require a <Field> decoration
}

export default FilterUserInput
