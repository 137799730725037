import React from 'react';
import {
    TextInput,
    LongTextInput,
    TextField,
    EditButton,
    FormTab,
    ShowButton,
    NumberInput,
    NumberField,
    DateInput,
    DateField,
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    Show,
    SimpleForm,
    Tab,
    TabbedForm,
    TabbedShowLayout
} from 'react-admin';
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import DocumentTitle from 'react-document-title'

import Icon from '../icons/subscriptionPlan.png'
import IconTint from '../IconTint'
export const SubscriptionPlansIcon = (props) => (<IconTint {...props} src={Icon}/>)

export const SubscriptionPlansList = (props) => (
    <DocumentTitle title="Binsto - Subscription Plans">
        <List title="Subscription Plans" {...props} >
            <Datagrid>
                <TextField source="name"/>
                <TextField source="description"/>
                <ActionToolbar>
                    <EditButton />
                    <ShowButton />
                </ActionToolbar>
            </Datagrid>
        </List>
    </DocumentTitle>
);

const SubscriptionPlansTitle = ({ record }) => {
    return <span>Subscription plan {record ? `"${record.name}"` : ''}</span>;
};

export const SubscriptionPlansShow = (props) => (
    <DocumentTitle title="Binsto - Show Subscription Plan">
        <Show title={<SubscriptionPlansTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="INFO">
                    <TextField source="name"/>
                    <TextField source="description"/>
                    <DateField label="Start Date" source="startAt"/>
                    <DateField label="End Date" source="endAt"/>
                </Tab>
                <Tab label="PRICES">
                    <NumberField label="Monthly Subscription" source="monthlyAmount" options={{ style: 'currency', currency: 'USD' }}/>
                    <NumberField label="Annual Subscription" source="annualAmount" options={{ style: 'currency', currency: 'USD' }}/>
                    <NumberField label="Bin Delivery" source="binDeliverPrice" options={{ style: 'currency', currency: 'USD' }}/>
                    <NumberField label="Take Away Bin" source="binTakeAwayPrice" options={{ style: 'currency', currency: 'USD' }}/>
                    <NumberField label="On Demand Bin Redelivery" source="onDemandBinRedeliverPrice" options={{ style: 'currency', currency: 'USD' }}/>
                    <NumberField label="Scheduled Bin Redelivery" source="scheduleBinRedeliverPrice" options={{ style: 'currency', currency: 'USD' }}/>
                </Tab>
                <Tab label="LIMITS">
                    <NumberField label="Maximum Free Bins" source="maxBins"/>
                    <NumberField label="Maximum Free Items" source="maxItems"/>
                    <NumberField label="Minimum Subscription Months" source="minMonths"/>
                </Tab>
                <Tab label="STATS">
                    <NumberField label="Status" source="status"/>
                    <TextField label="ID" source="id"/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    </DocumentTitle>
);

export const SubscriptionPlansEdit = (props) => (
    <DocumentTitle title="Binsto - Edit Subscription Plan">
        <Edit title={<SubscriptionPlansTitle />} {...props}>
            <TabbedForm>
                <FormTab label="INFO">
                    <TextInput source="name"/>
                    <LongTextInput source="description"/>
                    <DateInput label="Start Date" source="startAt"/>
                    <DateInput label="End Date" source="endAt"/>
                </FormTab>
                <FormTab label="PRICES">
                    <NumberInput label="Monthly Subscription" source="monthlyAmount"/>
                    <NumberInput label="Annual Subscription" source="annualAmount"/>
                    <NumberInput label="Bin Delivery" source="binDeliverPrice"/>
                    <NumberInput label="Take Away Bin" source="binTakeAwayPrice"/>
                    <NumberInput label="On Demand Bin Redelivery" source="onDemandBinRedeliverPrice"/>
                    <NumberInput label="Scheduled Bin Redelivery" source="scheduleBinRedeliverPrice"/>
                </FormTab>
                <FormTab label="LIMITS">
                    <NumberInput label="Maximum Free Bins" source="maxBins"/>
                    <NumberInput label="Maximum Free Items" source="maxItems"/>
                    <NumberInput label="Minimum Subscription Months" source="minMonths"/>
                </FormTab>
                <FormTab label="STATS">
                    <NumberInput label="Status" source="status"/>
                    <TextInput label="ID" source="id"/>
                </FormTab>
            </TabbedForm>
        </Edit>
    </DocumentTitle>
);

export const SubscriptionPlansCreate = (props) => (
    <DocumentTitle title="Binsto - Create Subscription Plan">
        <Create title="Create Subscription Plan" {...props}>
            <TabbedForm>
                <FormTab label="INFO">
                    <TextInput source="name"/>
                    <LongTextInput source="description"/>
                    <DateInput label="Start Date" source="startAt"/>
                    <DateInput label="End Date" source="endAt"/>
                </FormTab>
                <FormTab label="PRICES">
                    <NumberInput label="Monthly Subscription" source="monthlyAmount"/>
                    <NumberInput label="Annual Subscription" source="annualAmount"/>
                    <NumberInput label="Bin Delivery" source="binDeliverPrice"/>
                    <NumberInput label="Take Away Bin" source="binTakeAwayPrice"/>
                    <NumberInput label="On Demand Bin Redelivery" source="onDemandBinRedeliverPrice"/>
                    <NumberInput label="Scheduled Bin Redelivery" source="scheduleBinRedeliverPrice"/>
                </FormTab>
                <FormTab label="LIMITS">
                    <NumberInput label="Maximum Free Bins" source="maxBins"/>
                    <NumberInput label="Maximum Free Items" source="maxItems"/>
                    <NumberInput label="Minimum Subscription Months" source="minMonths"/>
                </FormTab>
                <FormTab label="STATS">
                    <NumberInput label="Status" source="status"/>
                    <TextInput label="ID" source="id"/>
                </FormTab>
            </TabbedForm>
        </Create>
    </DocumentTitle>
);
