import React from 'react';
import Card, { CardHeader } from 'material-ui/Card';
import List, { ListItem } from 'material-ui/List';
import NewUsersIcon from 'material-ui-icons/PersonAdd';
import FullNameField from '../fields/FullNameField';

const tintColor = '#4caf50'

const styles = {
    card: { borderLeft: 'solid 4px ' + tintColor, flex: '1', marginRight: '1em' },
    icon: { float: 'right', width: 64, height: 64, padding: 16, color: tintColor },
};

export default ({ users=[] }) => (
    <Card style={styles.card}>
        <NewUsersIcon style={styles.icon} />
        <CardHeader title="" subheader="New Users" />
        <List>
            {users.map(record =>
                <ListItem button component="a" href={`#/profiles/${record.id}/show`} key={record.id}>
                    <FullNameField record={record}/>
                </ListItem>
            )}
        </List>
    </Card>
);

