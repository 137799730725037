import React from 'react';
import {
    Filter,
    List,
    Edit,
    Create,
    Datagrid,
    ReferenceField,
    EditButton,
    NumberInput,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleForm,
    ShowButton,
    SimpleShowLayout,
    TextInput
} from 'react-admin';
import FullNameField from '../fields/FullNameField'
import ActionToolbar from '../uicomponents/ActionToolbar'
import CreateToolbar from '../uicomponents/CreateToolbar'
import FilterUserInput from '../inputs/FilterUserInput'

import DocumentTitle from 'react-document-title'

export const validateFields = (values, allValues) => {
    const errors = {};
    if (!values.count) {
        errors.count = ['You must specify an item count'];
    }
    if (!values.orderId) {
        errors.orderId = ['You must specify an order'];
    }
    if (!values.productId) {
        errors.productId = ['You must specify a product'];
    }
    return errors
};

const styles = {
    id: {
        display: 'none'
    }
}

export const OrderItemsEdit = ({id, reference, record, ...props}) => (
    <DocumentTitle title="Binsto - Edit Blocked User">
        <Edit title="Edit Order Item" {...props}>
            <SimpleForm validate={validateFields}>
                <NumberInput label="COUNT" source="count"/>
                <ReferenceInput label="PRODUCT" source="productId" reference="products">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="orderId" defaultValue={id} style={styles.id}/>
            </SimpleForm>
        </Edit>
    </DocumentTitle>
);

export const OrderItemsCreate = (props) => (
    <DocumentTitle title="Binsto - Create Blocked User">
        <Create title="Add Item to Order" {...props}>
            <SimpleForm validate={validateFields}>
                <NumberInput label="COUNT" source="count"/>
                <ReferenceInput label="PRODUCT" source="productId" reference="products">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="orderId" defaultValue="" style={styles.id}/>
            </SimpleForm>
        </Create>
    </DocumentTitle>
);