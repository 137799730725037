import React, { Component } from 'react';
import { connect } from 'react-redux';
import {authUser} from './auth/firestoreAuthClient'

const titleStyle = {
    'verticalAlign': 'middle',
    'height': '48px'
};

class AppBarTitle extends Component {
    render() {
        return <img src={this.props.isLoggedIn ? authUser.brand.logoURL : ""} alt='' style={titleStyle}/>
    }
}

const mapStateToProps = state => ({
    isLoggedIn : state.admin.auth.isLoggedIn
})

export default connect(mapStateToProps)(AppBarTitle)

