// in src/Menu.js
import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, DashboardMenuItem, getResources, WithPermissions } from 'react-admin';
import Divider from 'material-ui/Divider'
import BroadcastIcon from 'material-ui-icons/AllOut'
import { UserIcon } from './viewers/Users'
import { ItemIcon } from './viewers/Items'
import { ContainerIcon } from './viewers/Containers'
import { StorageIcon } from './viewers/StorageLocations'
import { CategoryIcon } from './viewers/Categories'
import { BrandIcon } from './viewers/Brands'
import { MessageIcon } from './viewers/Messages'
import { SentMessagesIcon } from './viewers/SentMessages'
import { ReceivedMessagesIcon } from './viewers/ReceivedMessages'
import { UserCustomizationsIcon } from './viewers/UserCustomizations'
import { BlockIcon } from './viewers/Blocks'
import { TagsIcon } from './viewers/Tags'
import { SubscriptionPlansIcon } from './viewers/SubscriptionPlans'
import { ProductsIcon } from './viewers/Products'
import { OrdersIcon } from './viewers/Orders'
import { TransactionsIcon } from './viewers/Transactions'
import { LabelLayoutsIcon } from './viewers/LabelLayouts'
import { IntrosIcon } from './viewers/Intros'
import { InitialUserDataIcon } from './viewers/InitialUserData'
import {authUser} from './auth/firestoreAuthClient'
import { Rights, hasRight } from './Roles'

import IconTint from './IconTint'

const headerStyle = {
    textAlign: "center",
    fontFamily: 'Roboto',
    backgroundColor: "gray",
    color: "white",
    padding: "10px",
    whiteSpace: "nowrap"
}

const iconStyle = {
    width: "24px",
    height: "24px"
}

const tintIconStyle = {
    width: "24",
    height: "24",
    color: "#666666"
}

const Menu = ({ permissions, resources, onMenuTap, logout, dense, ...props }) => {


    return <div>
        <DashboardMenuItem onClick={onMenuTap}/>
        {
            [
                {name: 'divider', id: "1"},
                {name: 'header', id: "2", label: authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? "Content Management" : "My Storage", tinyLabel: "CM", rights: Rights.userStorage},
                {name: 'items', label: "Items", icon: <ItemIcon {...tintIconStyle} />, rights: Rights.userStorage},
                {name: 'containers', label: "Bins", icon: <ContainerIcon {...tintIconStyle}/>, rights: Rights.userStorage},
                {name: 'storagelocations', label: "Storage Locations", icon: <StorageIcon {...tintIconStyle}/>, rights: Rights.userStorage},
                {name: 'divider', id: "3", rights: Rights.userStorage},

                {name: 'header', id: "4", label: "User Management", tinyLabel: "UM", rights: Rights.userManagement},
                {name: 'profiles', label: "Users", icon: <UserIcon style={iconStyle}/>, rights: Rights.userManagement},
                {name: 'blocks', label: "Blocked Users", icon: <BlockIcon style={iconStyle}/>, rights: Rights.siteAdministration},
                {name: 'history', label: "User Customizations", icon: <UserCustomizationsIcon style={iconStyle}/>, rights: Rights.siteAdministration},
                {name: 'tags', label: "Tags", icon: <TagsIcon {...tintIconStyle}/>, rights: Rights.siteAdministration},
                {name: 'divider', id: "5", rights: Rights.userManagement},

                {name: 'header', id: "10", label: "Messages", tinyLabel: "M", rights: Rights.messages},
                {name: 'messages', label: "All Messages", icon: <MessageIcon style={iconStyle}/>, rights: Rights.siteAdministration},
                {name: 'sentmessages', label: "My Sent Messages", icon: <SentMessagesIcon style={iconStyle}/>, rights: Rights.messages},
                {name: 'receivedmessages', label: "My Received Messages", icon: <ReceivedMessagesIcon style={iconStyle}/>, rights: Rights.messages},
                {name: 'broadcast', label: "Send Broadcast", icon: <BroadcastIcon style={iconStyle}/>, rights: Rights.userManagement},
                {name: 'divider', id: "11", rights: Rights.messages},

                {name: 'header', id: "6", label: "Administration", tinyLabel: "A", rights: Rights.uiBranding},
                {name: authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? 'brands' : 'brands/' + (authUser ? authUser.brand.id : ""), label: authUser && hasRight(authUser.profile.role, Rights.siteAdministration) ? "Clients" : "Account Settings", icon: <BrandIcon {...tintIconStyle}/>, rights: Rights.uiBranding},
                {name: 'labellayouts', label: "Label Layouts", icon: <LabelLayoutsIcon {...tintIconStyle}/>, rights: Rights.siteAdministration},
                {name: 'intro', label: "Intro Screens", icon: <IntrosIcon {...tintIconStyle}/>, rights: Rights.uiBranding},
                {name: 'initialuserdata', label: "Initial User Data", icon: <InitialUserDataIcon {...tintIconStyle}/>, rights: Rights.siteAdministration},
                {name: 'categories', label: "Initial Categories", icon: <CategoryIcon {...tintIconStyle}/>, rights: Rights.siteAdministration},
                {name: 'divider', id: "7", rights: Rights.uiBranding},

                {name: 'header', id: "8", label: "Order Management", tinyLabel: "OM", rights: Rights.manageOrders},
                {name: 'products', label: "Products", icon: <ProductsIcon {...tintIconStyle}/>, rights: Rights.manageOrders},
                {name: 'orders', label: "Orders", icon: <OrdersIcon {...tintIconStyle}/>, rights: Rights.manageOrders},
                {name: 'transactions', label: "Transactions", icon: <TransactionsIcon {...tintIconStyle}/>, rights: Rights.manageOrders},
                {name: 'subscriptionplan', label: "Subscription Plans", icon: <SubscriptionPlansIcon {...tintIconStyle}/>, rights: Rights.siteAdministration},
                {name: 'divider', id: "9", rights: Rights.manageOrders},
            ].map(item => (
            <WithPermissions key={item.name + item.id} render={ ({permissions}) => ( hasRight(permissions, item.rights) ? (
                    item.name === 'divider' ? <Divider key={item.id}/> :
                        item.name === 'header' ? <div key={item.id} style={headerStyle}><span>{props.open ? item.label : item.tinyLabel}</span></div> :
                            <MenuItemLink
                                key={item.name}
                                to={`/${item.name}`}
                                primaryText={item.label}
                                leftIcon={item.icon}
                                onClick={onMenuTap}
                            />
            ) : null )}/>
        ))}
    </div>
}

const mapStateToProps = state => ({
    resources: getResources(state),
    open: state.admin.ui.sidebarOpen,
})
export default connect(mapStateToProps)(Menu);

