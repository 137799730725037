import React from 'react';
import Card, { CardHeader } from 'material-ui/Card';
import { UserIcon } from '../viewers/Users'

const tintColor = "#31708f"

const styles = {
    card: { borderLeft: 'solid 4px ' + tintColor, flex: '1', marginRight: '1em', textDecoration: "none" },
    icon: { float: 'right', width: 64, height: 64, padding: 16, color: tintColor },
};

export default ({ value }) => (
    <Card style={styles.card} component="a" href={`#/profiles/`}>
        <UserIcon style={styles.icon} />
        <CardHeader
            title={value}
            subheader="Total Users"
        />
    </Card>
);
