import React from 'react';
import AvatarField from './AvatarField';
import pure from 'recompose/pure';

const FullNameField = ({ record = {}, size = 25 }) => {
    const styles = {
        div: {
            display: 'flex'
        },
        span: {
            display: 'inline-block',
            fontFamily: 'Roboto',
            whiteSpace: 'nowrap',
            lineHeight: '40px'
        }
    }
    return <div style={styles.div}>
        <AvatarField record={record} size={size}/>
        <span style={styles.span}>&nbsp;</span>
        <span style={styles.span}>{record.displayName ? record.displayName : record.email}</span>
    </div>;
}

const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
    source: 'last_name',
    label: 'Display name',
};

export default PureFullNameField;
