import React, { Children, cloneElement } from 'react';
import {
    SelectInput,
    BooleanField,
    BooleanInput,
    Edit,
    FormTab,
    List,
    SimpleForm,
    ImageInput,
    SimpleList,
    Datagrid,
    DateField,
    EmailField,
    ImageField,
    NumberField,
    NumberInput,
    TabbedForm,
    TextField,
    Filter,
    NullableBooleanInput,
    DateInput,
    SelectField,
    TextInput,
    EditButton,
    SimpleShowLayout,
    TabbedShowLayout,
    ReferenceManyField,
    ReferenceInput,
    ReferenceField,
    Tab,
    Show,
    ShowButton,
    Responsive
} from 'react-admin';
import Icon from 'material-ui-icons/Person';
import FirstImageField from '../fields/FirstImageField'
import FullNameField from '../fields/FullNameField';
import { Roles } from "../Roles"
import { withStyles } from 'material-ui/styles';
import _ from 'lodash'
import ActionToolbar from '../uicomponents/ActionToolbar'
import DocumentTitle from 'react-document-title'
import { Rights, hasRight } from '../Roles'
import {authUser} from '../auth/firestoreAuthClient'
import TagsField  from '../fields/TagsField'

export const UserIcon = Icon;

const styles = {
    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
};

const roleChoices =  _.map(Object.keys(Roles), function(key) { return {id: key, name: Roles[key].name} })

const UserFilter = ({permissions, ...props}) => (
    <Filter {...props}>
        <TextInput label="Display Name" source="q" alwaysOn />
        <SelectInput label="Role" source="role" choices={roleChoices}/>
        { hasRight(permissions, Rights.siteAdministration) ?
        <ReferenceInput label="Client" source="brand" reference="brands">
            <SelectInput optionText="name" />
        </ReferenceInput> : null }
        <DateInput label="Subscription expires at" source="subscriptionExpiresAt" />
        <NullableBooleanInput label="Is Disabled" source="isDisabled" defaultValue={false} />
        <NullableBooleanInput label="Show EULA" source="showEULA" defaultValue={true} />
    </Filter>
);

export const UserList = withStyles(styles)(({ permissions, classes, ...props }) => (
    <DocumentTitle title="Binsto - Users">
        <List
            {...props}
            filters={<UserFilter permissions={permissions}/>}
            filter={hasRight(permissions, Rights.siteAdministration) ? null : { "brand": authUser ? authUser.brand.id : ""}}
            sort={{ field: 'displayName', order: 'ASC' }}
            title="Users"
        >
            <Responsive
                small={
                    <SimpleList
                        primaryText={record => record.displayName}
                        rightIcon={record => <FullNameField record={record} />}
                        secondaryText={record => record.email}
                    />
                }
                medium={
                    <Datagrid>
                        <FullNameField label="DISPLAY NAME"/>
                        <EmailField label="EMAIL" source="email" />
                        <SelectField label="ROLE" source="role" choices={roleChoices}/>
                        { hasRight(permissions, Rights.siteAdministration) ?
                        <ReferenceField label="CLIENT" source="brand" reference="brands">
                            <TextField source="name" />
                        </ReferenceField>
                        : null }
                        <ActionToolbar>
                            <EditButton />
                            <ShowButton />
                        </ActionToolbar>
                    </Datagrid>
                }
            />
        </List>
    </DocumentTitle>
));

const UserTitle = ({ record }) => {
    return <span>User {record ? `"${record.displayName ? record.displayName : record.email}"` : ''}</span>;
};

export const UserShow = ({ permissions, ...props }) => (
    <DocumentTitle title="Binsto - Show User">
        <Show title={<UserTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="INFO">
                    <ImageField source="imageURL" label="AVATAR" sortable={false}/>
                    <TextField label="DISPLAY NAME" source="displayName"/>
                    <TextField label="EMAIL ADDRESS" source="email"/>
                    <SelectField label="ROLE" source="role" choices={roleChoices}/>
                    { hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceField label="CLIENT" source="brand" reference="brands">
                        <TextField source="name" />
                    </ReferenceField> : null }
                    <BooleanField label="Account Disabled" source="isDisabled"/>
                </Tab>
                <Tab label="PRIVACY">
                    <BooleanField label="STAY LOGGED IN" source="stayLoggedIn"/>
                    <BooleanField label="ITEMS DEFAULT TO PRIVATE" source="itemsPrivate"/>
                    <BooleanField label="BINS DEFAULT TO PRIVATE" source="binsPrivate"/>
                    <BooleanField label="STORAGE DEFAULTS TO PRIVATE" source="storagePrivate"/>
                    <BooleanField label="PRIVATE PROFILE" source="profilePrivate"/>
                </Tab>
                <Tab label="SUBSCRIPTION">
                    <TextField label="PLAN" source="subscriptionPlanId"/>
                    <DateField label="EXPIRES" source="subscriptionExpiresAt"/>
                </Tab>
                <Tab label="INTERNAL">
                    <TextField label="LOCK CODE" source="lockCode"/>
                    <TextField label="ID" source="uid"/>
                    <NumberField label="BADGE COUNT" source="badge"/>
                    <NumberField label="NEXT BIN ID" source="nextContainerID"/>
                </Tab>
                <Tab label="ITEMS">
                    <ReferenceManyField label="" reference="items" target="_info.ownedBy">
                        <Datagrid>
                            <FirstImageField source="imageRefs" label="" sortable={false}/>
                            <TextField label="NAME" source="name" />
                            <TagsField label="TAGS"/>
                        </Datagrid>
                    </ReferenceManyField>
               </Tab>
                <Tab label="BINS">
                    <ReferenceManyField label="" reference="containers" target="_info.ownedBy">
                        <Datagrid>
                            <FirstImageField source="imageRefs" label="" sortable={false}/>
                            <TextField label="NAME" source="name" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="STORAGE">
                    <ReferenceManyField label="" reference="storagelocations" target="_info.ownedBy">
                        <Datagrid>
                            <FirstImageField source="imageRefs" label="" sortable={false}/>
                            <TextField label="NAME" source="name" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    </DocumentTitle>
);



export const UserEdit = ({permissions, ...props}) => (
    <DocumentTitle title="Binsto - Edit User">
        <Edit title={<UserTitle />} {...props}>
            <TabbedForm>
                <FormTab label="INFO">
                    <ImageInput source="imageURL" label="AVATAR" sortable={false}/>
                    <TextInput label="DISPLAY NAME" source="displayName"/>
                    <TextInput label="EMAIL ADDRESS" source="email"/>
                    { hasRight(permissions, Rights.siteAdministration) ?
                    <SelectInput label="ROLE" source="role" choices={roleChoices}/>
                        : null }
                    { hasRight(permissions, Rights.siteAdministration) ?
                    <ReferenceInput label="CLIENT" source="brand" reference="brands">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                        : null }
                    <BooleanInput label="Account Disabled" source="isDisabled"/>
                </FormTab>
                <FormTab label="PRIVACY">
                    <BooleanInput label="STAY LOGGED IN" source="stayLoggedIn"/>
                    <BooleanInput label="ITEMS DEFAULT TO PRIVATE" source="itemsPrivate"/>
                    <BooleanInput label="BINS DEFAULT TO PRIVATE" source="binsPrivate"/>
                    <BooleanInput label="STORAGE DEFAULTS TO PRIVATE" source="storagePrivate"/>
                    <BooleanInput label="PRIVATE PROFILE" source="profilePrivate"/>
                </FormTab>
                <FormTab label="SUBSCRIPTION">
                    <TextInput label="PLAN" source="subscriptionPlanId"/>
                    <DateInput label="EXPIRES" source="subscriptionExpiresAt"/>
                </FormTab>
                <FormTab label="INTERNAL">
                    <TextInput label="LOCK CODE" source="lockCode"/>
                    <NumberInput label="BADGE COUNT" source="badge"/>
                    <NumberInput label="NEXT BIN ID" source="nextContainerID"/>
                </FormTab>
            </TabbedForm>
        </Edit>
    </DocumentTitle>
);

import AddUser from '../customPages/AddUser'
export const UserCreate = AddUser