import React from 'react';
import { TextField } from 'react-admin';
import get from 'lodash.get'

const styles = {
    div: {
        display: 'inilne-grid',
        backgroundColor: '#eeeeee',
    },
    field: {
        margin: '1em'
    }
}

const AddressField = ({ record, source, ...props  }) =>
    <div style={styles.div}>
        <span style={styles.field}>{get(record, source + ".name")}</span>
        <span style={styles.field}>{get(record, source + ".address1")}</span>
        <span style={styles.field}>{get(record, source + ".address2")}</span>
        <span style={styles.field}>{get(record, source + ".city")}</span>
        <span style={styles.field}>{get(record, source + ".state")}</span>
        <span style={styles.field}>{get(record, source + ".zip")}</span>
    </div>

AddressField.defaultProps = {
    addLabel: true,
    label: 'Address'
}

export default AddressField;
