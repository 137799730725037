import React, { Component } from 'react';
import restClient from '../restClient'
import { GET_ONE } from 'react-admin'

const styles = {
    img: {
        height: 64,
        width: 64,
        borderRadius: 4,
        padding: 2
    }
};

class DatabaseFirstImage extends Component {

    state = {};

    componentDidMount() {
        const {reference, source, ...props} = this.props;
        restClient(GET_ONE, reference, { id: source } ).then(response => { this.setState({imageURL: response.data.imageRefs.length > 0 ? response.data.imageRefs[0] : ""})} )
    }

    render() {
        const {
            imageURL
        } = this.state;

        return (
            <img  key={imageURL} style={styles.img} src={imageURL} alt="" />
        )
    }
}

export default DatabaseFirstImage
