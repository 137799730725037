import firebase from 'firebase'
import firestore from 'firebase/firestore'
import {authUser} from '../auth/firestoreAuthClient'

const apiBase = "api/"

const BackEndAPI = (endpoint, params) => {
    const db = firebase.firestore()
    let ref = db.collection(apiBase).doc(authUser.profile.uid).collection(endpoint).doc()
    ref.set(params)
    // wait for API response
    return new Promise((resolve,reject) => {
        ref.onSnapshot(function (doc) {
            const response = doc.data()._response
            if (response) {
                if (response.success)
                    resolve(response.success)
                else
                    reject(response.error)
            }
        })
    })
}


export const CreateUser = (email, password) => {
    return BackEndAPI('createuser', {email: email, password: password})
}

export const UpdateUser = (userId, params) => {
    return BackEndAPI('updateuser', params)
}

export const DeleteUser = (userId) => {
    return BackEndAPI('deleteuser', {userId: userId})
}
